/* global $, alert, document, window, setInterval, clearInterval, setTimeout */
// Load modules
// ...
/* global $, Modernizr, toastr, printError, printWarning*/
/*
    All generic ajax functions go here. (updated by HY 2017-01-23)
*/

var ajaxLib = {
  pd: function() {
    function getDepartmentList(referURL, beforeCB, successCB, errorCB, doneCB) {
      $.ajax({
        url: 'https://www.secure-payment-portal.com/orders_launch/account/user_service_one_step.php',
        type: 'POST',
        dataType: 'json',
        data: {
          refer_url: referURL,
          what_to_do: 'all_payroll_plans'
        },
        beforeSend: beforeCB,
        complete: doneCB,
        success: successCB,
        error: errorCB
      });
    }
    return {
      getDepartmentList: getDepartmentList
    };
  },
  mailList: (function() {
    var _ajxSubmit = function(metaData, frmData) {
      $.ajax({
        url: metaData.endPoint,
        type: 'GET', //metaData.requestType,
        data: frmData,
        success: function() {
          var success_msg = $('<p></p>').addClass('has-success t-center').text('Thank you for joining!').css({
            'font-size': '1em',
            'font-weight': 'bold',
            'text-transform': 'uppercase'
          });
          $('.ajx-mailing-list')
            .css('opacity', '0')
            .html(success_msg)
            .delay(300)
            .animate({
              'opacity': '1'
            }, 400);
        },
        error: function(request, errorType, errorMessage) {
          printWarning(request);
          printWarning(errorType);
          printWarning(errorMessage);
        }
      });
    };
    return {
      init: function() {
        $('.ajx-mailing-list-form').on('submit', function(event) {
          var frm = $(this);
          var frmData = $(this).serialize();
          var frmAttr = {
            endPoint: frm.attr('action'),
            requestType: frm.attr('method')
          };
          if (typeof Modernizr !== 'undefined') {
            if (Modernizr.input.required) {
              event.preventDefault();
              _ajxSubmit(frmAttr, frmData);
            } else {
              printWarning('HTML5 Validation is not supported in this browser.');
            }
          }
        });
      }
    };
  }()), // --> end mailList
  shoppingCart: (function() {
    var cart = {
      getQuantity: function() {
        var qtyTotal = 0,
          qtyInputs = $('.ajx-cart-qty:enabled'), 
          i;

        for (i = 0; i < qtyInputs.length; i += 1) {
          qtyTotal += parseInt(qtyInputs.eq(i).val());
        }

        return qtyTotal;
      },
      getSubtotal: function() {
        var subtotal = 0,
          totals = $('.total'),
          i;

        for (i = 0; i < totals.length; i += 1) {
          subtotal += parseFloat(totals.eq(i).html());
        }

        return subtotal.toFixed(2);
      },
      updatePrices: function() {
        var line = $(this).data('line');
        var row = $('#line_' + line);
        var qty = Number(row.find('.ajx-cart-qty').val());
        var price = row.find('.price').text();
        if (isNaN(qty) || qty === '' || qty === 0 || qty < 0) {
          row.find('.ajx-cart-qty').addClass('has-error').focus();
        } else {
          $('#cart_error').addClass('hidden');
          row.find('span.has-error').text('');
          row.find('.ajx-cart-qty').removeClass('has-error');
          cart.ajxUpdateItemQty(line, qty, price);
        }
      },
      printQuantityError: function(msg) {
        var qtyErrorDiv = $('#quantity_error'),
          qtyErrorMessage = $('#quantity_error_message');
        if (qtyErrorDiv.hasClass('hidden')) {
          qtyErrorMessage.html(msg);
          qtyErrorDiv.removeClass('hidden');
        }
                // window.setTimeout(cart.hideQuantityError, 7000);
      },
      hideQuantityError: function() {
        var qtyErrorDiv = $('#quantity_error');
        qtyErrorDiv.addClass('hidden');
      },
      ajxUpdateItemQty: function(line, qty, price) {
        if (line && line > 0) {
          $.ajax({
            url: '/cart/index.php?ajax=1&what_to_do=update&which_line=' + line + '&quantity_' + line + '=' + qty,
            type: 'POST',
            beforeSend: function() {
              $('.grand-total').addClass('attach-loader');
              $('.cart-checkout').addClass('disabled');
            },
            success: function(response) {
              var embroidery = response.embroidery;
              var embroideryLine;
              var productLine = $('#line_' + line);
              var inventoryData = response.item_max_quantity;
              var itemsLeft = $('.js-cart-row').length;
              var rowTotal, updatedRowTotal, cartQuantity;
              if (parseInt(qty) === 0 && parseInt(itemsLeft) === 0) {
                $('#cart_summary').remove();
                                // reload the page if no items left in the cart
                window.location.assign(location);
              } else {
                for (var i = 0; i < inventoryData.length; i += 1) {
                  if (parseInt(inventoryData[i].order_line) === parseInt(line)) {
                    if (inventoryData[i].status === 'FAILED') {
                      if (inventoryData[i].max_quantity > 0) {
                        qty = inventoryData[i].max_quantity;
                        productLine.find('.ajx-cart-qty').val(qty);
                        toastr.warning('We do not carry more than ' + qty + ' items of this product.');
                        break;
                      }
                      // this will not hit according to new protocol as max quantity will always be positive or 0
                      else if (inventoryData[i].max_quantity === -1) {
                        toastr.warning('We are sorry, but you already exceeded number of items for this product.');
                        // cart.printQuantityError(messageHTML);
                        break;
                      } else {
                        printError('Cart quantity process error: ', inventoryData[i]);
                      }
                    } else {
                      cart.hideQuantityError();
                      break;
                    }
                  }
                }
                rowTotal = productLine.find('.total').text();
                updatedRowTotal = parseFloat(price) * parseInt(qty);

                ///////////////////////
                /// SPI Embroidery ///
                /////////////////////
                if (embroidery && embroidery.is_spi_embroidery) {
                  embroideryLine = $('#line_' + embroidery.embroidery_line);
                  embroideryLine.find('.ajx-cart-qty').val(productLine.find('.ajx-cart-qty').val());
                  embroideryLine.find('.total').text(parseFloat(embroidery.current_price).toFixed(2));
                }

                ///////////////////////////
                /// non-SPI Embroidery ///
                /////////////////////////
                if (embroidery && !embroidery.is_spi_embroidery && embroidery.current_price) {
                  productLine
                                        .find('.embroidery-total').text(parseFloat(embroidery.current_price).toFixed(2));
                  updatedRowTotal += parseFloat(embroidery.current_price.toFixed(2));
                }

                cartQuantity = cart.getQuantity();
                rowTotal = updatedRowTotal.toFixed(2);
                $('#line_' + line).find('.total').text(rowTotal);
//                $('#cart').text(cartQuantity + ' item(s)');
                //$('#cart').text(cartQuantity);
                  $('#cart').text(response.cart_count);
                $('.grand-total').text(cart.getSubtotal());
              }
            },
            complete: function() {
              $('.grand-total').removeClass('attach-loader');
              $('.cart-checkout').removeClass('disabled');
            },
            error: function(error) {
              printError('Failed to update'); // silent error
              printError('AJAX ERROR: ', error.message);
            }
          });
        }
      },
      ajxRemoveCartItem: function(line) {
        if (line > 0) {
          $.ajax({
            url: '/cart/index.php?ajax=1&what_to_do=delete&which_line=' + line,
            beforeSend: function() {
              $('.grand-total').addClass('attach-loader');
              $('.cart-checkout').addClass('disabled');
            },
            success: function() {
              var productLine = $('#line_' + line);
              var embroideryLine = productLine.next('.is-spi-embroidery');
              var itemsInCart;

              productLine.remove();
              if (embroideryLine.length) {
                embroideryLine.remove();
              }
              itemsInCart = $('.js-cart-row').length;
              $('.grand-total').text(cart.getSubtotal());
//              $('#cart').text(cart.getQuantity() + ' item(s)');
              $('#cart').text(cart.getQuantity());
              if (!itemsInCart) {
                $('#cart_summary').remove();
                window.location.assign(window.location);
              }
            },
            complete: function() {
              $('.grand-total').removeClass('attach-loader');
              $('.cart-checkout').removeClass('disabled');
            },
            error: function(error) {
              printError(error.message);
              printError('failed to remove'); // silent error
            }
          });
        }
      },
      ajxUpdateLineMessage: function(line, msg) {
        $.ajax({
          url: '/cart/index.php?ajax=1&what_to_do=update_msg&which_line=' + line + '&line_msg=' + msg,
          error: function(req, errType, errMsg) {
            printError(errMsg);
          }
        });
      }
    };
    return {
      init: function() {
        toastr.options.closeButton = true;
        toastr.options.preventDuplicates = true;
        toastr.options.positionClass = 'toast-top-full-width';
        // allow quantity error message to be dismissed
        $('#quantity_error').message();
        // window.setTimeout(cart.hideQuantityError, 7000);
        // On 'add message' click show text field.
        $('.js-show-note').on('click', function(event) {
          event.preventDefault();
          var showNoteLink = $(this);
          var note = $(this).siblings('.js-note');
          var noteMsg = note.find('.js-note-msg');
          noteMsg.focus();
          note.show();
          showNoteLink.hide();
        });
        $('.js-save-note').on('click', function() {
          var saveNoteBtn = $(this);
          var line = saveNoteBtn.parents('.js-cart-row').attr('data-line');
          var msg = saveNoteBtn.parents('.js-note').find('.js-note-msg').val();

          cart.ajxUpdateLineMessage(line, msg);
          if (msg !== '') {
            $(this).parents('.js-note').find('.js-note-msg').addClass('noedit');
            saveNoteBtn.text('Saving...');
            setTimeout(function() {
              saveNoteBtn.text('Save');
            }, 1000);
          } else {
            saveNoteBtn.text('Save');
            saveNoteBtn.parents('.js-cart-row').find('.js-show-note').show();
            saveNoteBtn.parents('.js-note').find('.js-note-msg').removeClass('noedit');
            saveNoteBtn.parents('.js-note').hide();
          }
        });
        $('.js-remove-note').on('click', function() {
          var removeNoteBtn = $(this);
          var line = removeNoteBtn.parents('.js-cart-row').attr('data-line');

          removeNoteBtn.parents('.js-note').find('.js-note-msg').val('');
          removeNoteBtn.parents('.js-note').find('.js-note-msg').removeClass('noedit');
          cart.ajxUpdateLineMessage(line, '');
          removeNoteBtn.parents('.js-cart-row').find('.js-show-note').show();
          removeNoteBtn.parents('.js-note').hide();
        });
        $('.ajx-cart-remove a').on('click', function(event) {
          event.preventDefault();
          $(this).parents('.js-cart-row').addClass('t-center').html('Deleting ... <img src="/meta/pie/0004/0004/0777/icons/ajax-loader.gif">');
          var line = $(this).attr('data-line');
          //var amt = $(this).attr('data-amount');
          cart.ajxRemoveCartItem(line);
        });
        $('.ajx-cart-update-qty').on('click', function(event) {
          event.preventDefault();
          cart.updatePrices.call(this);
        }).end();
        $('.ajx-cart-qty').on('blur', function() {
          cart.updatePrices.call(this);
        }).end();
        $('#payment').on('submit', function(event) {
          var cartHasErrors = $('#shopping_bag').find('input.has-error').length;
          if (cartHasErrors) {
            event.preventDefault();
            toastr.error('Please provide valid quantity values before checkout.');
            //$('#cart_error').removeClass('hidden');
          } else {
            $('.cart-btn').addClass('disabled');
          }
        });
      }, // --> end of shoppingCart.init()
    };
  }()), // --> end shoppingCart
}; // --> end ajaxLib
/* global $ */

var editableUI = (function() {
  function _toggleEditMode(options) {
    var caller = options.caller;
    var target = options.target;
    var edit = options.edit;

    if (edit) {
      caller.addClass('hidden');
      target.removeClass('hidden');
    } else {
      caller.removeClass('hidden');
      target.addClass('hidden');
      target
				.find('.form-ctrl.has-error')
				.removeClass('has-error')
				.end()
				.find('span.has-error').remove();
    }
  }

  function _attachRegActions () {
    var editBtn = $('[data-toggle="edit"]');
    var cancelBtn = $('[data-toggle="cancel edit"]');
    editBtn.on('click.edit_start', function() {
      var b = $(this);
      var options = {
        caller: $(b.data('caller')),
        target: $(b.data('target')),
        edit: true
      };
      _toggleEditMode(options);
    });

    cancelBtn.on('click.edit_cancel', function() {
      var b = $(this);
      var options = {
        caller: $(b.data('caller')),
        target: $(b.data('target')),
        edit: false
      };
      _toggleEditMode(options);
    });
  }

  function initOnLoad() {
    _attachRegActions();
  }

  return {
    init: initOnLoad
  };
}());

/* global $ */

var filtersAndSort = (function () {
  var _toggleCheck = function () {
    var option = $(this),
      label = option.find('label'),
      url = option.find('a').attr('href');
    if(label.hasClass('lbl-checked')) {
      label
				.removeClass('lbl-checked')
				.find('input')
				.attr('checked', 'false');
    } else {
      label
				.addClass('lbl-checked')
				.find('input')
				.attr('checked', 'true');
    }
		/*
			Disable all filter checkboxes so that
			accidental clicks on other filter
			options do not happen when page is
			reloaded.
		*/
    if (url.length) {
      option.find('a').trigger('gaevent');
      window.location.assign(url);
      $('#filter_options')
				.find('.option')
				.addClass('disabled');
    }
  };
  var _hiliteSelection = function () {
    var hasOptionsSelected = $(this)
				.find('label')
				.filter('.lbl-checked')
				.length,
      hasAvailableOptions = $(this).find('.option:not(.not_available)');

    if (hasOptionsSelected) {
      $(this).addClass('has-selection');
    }
    if(! hasAvailableOptions) {
      $(this).hide();
    }
  };
	/*
        Hide show filter and sort functions:
        This is intendented to work on devices that support touch
        (ie smartphones and tablets)
        NOTE: mouse hover events controlled by CSS
    */
  var _revealFilterOptions = function (event) {
    event.stopPropagation();
    $('.mod-touch .sort').find('.options').removeClass('revealed');
    $(this).siblings().find('.options').removeClass('revealed');
    $(this).find('.options').toggleClass('revealed');
  };
  var _revealSortOptions = function (event) {
    event.stopPropagation();
    $('.mod-touch .filter').find('.options').removeClass('revealed');
    $(this).find('.options').toggleClass('revealed');
  };
  var _hideAllOptions = function () {
    $('.mod-touch .options').removeClass('revealed');
  };
    /*
        Sticky filter and sort panel:
        If window is scrolled to panel top, it will persist at window top
        Else, it will stay back at its original position
    */
  var _stickyPanel = function () {
    var panel = $(this),
      panelPos = panel.offset().top,
      windowPos;
    $(window).on('scroll', function () {
      windowPos = $(this).scrollTop();
      if (windowPos >= (panelPos * 2) - ((panel.outerHeight() / 2 ) + 20)) {
        panel.addClass('sticky');
      } else {
        panel.removeClass('sticky');
      }
    });
  };
  return {
    init: function () {
      var filterSortPanel = $('#sorting_and_filtering'),
        filters = $('.filter'),
        filterHasOptions = $('#filter_options').children().length,
        mobileFilters = $('.mod-touch .filter'),
        mobileSort = $('.mod-touch .sort');

      if (!filterHasOptions) {
				// hide 'Filter By' if no filter options.
        $('#filters').find('.handle').addClass('hidden');
      }
      if (filterSortPanel.length) {
        _stickyPanel.apply(filterSortPanel);
      }
      if (mobileFilters.length || mobileSort.length) {
        mobileFilters.on('click', _revealFilterOptions);
        mobileSort.on('click', _revealSortOptions);
        $(document).on('click', _hideAllOptions);
      }
      if (filters.length) {
        filters.each(function () {
          var filter = $(this),
            filterOption = filter.find('.option'),
            link = filterOption.find('a');
          link.addClass('disabled');
          filterOption.css('cursor', 'pointer');
          _hiliteSelection.apply(filter);
          filterOption.on('click', _toggleCheck);
        });
      } else {
				//-- no filters
      }
    }
  };
}());

// Helper functions
function getFileNameFromURL (url) {
  return /[^\/\\&\?]+\.\w{3,4}(?=([\?&].*$|$))/gi.exec(url)[0];
}

function printDebug (message) {
  if ('debug' in console) {
    console.debug(message);
  } else {
    console.log(message);
  }
}
function printError (message) {
  if ('error' in console) {
    console.error(message);
  } else {
    console.log(message);
  }
}
function printWarning (message) {
  if ('warn' in console) {
    console.warn(message);
  } else {
    console.log(message);
  }
}
/* global $, jQuery */

/*
    Fancybox custom code
*/
(function ($, F) {

    // Opening animation - fly from the top
  F.transitions.dropIn = function () {
    var endPos = F._getPosition(true);

    endPos.top = (parseInt(endPos.top, 10) - 200) + 'px';
    endPos.opacity = 0;

    F.wrap.css(endPos).show().animate({
      top: '+=200px',
      opacity: 1
    }, {
      duration: F.current.openSpeed,
      complete: F._afterZoomIn
    });
  };

    // Closing animation - fly to the top
  F.transitions.dropOut = function () {
    F.wrap.removeClass('fancybox-opened').animate({
      top: '-=200px',
      opacity: 0
    }, {
      duration: F.current.closeSpeed,
      complete: F._afterZoomOut
    });
  };

    // Next gallery item - fly from left side to the center
  F.transitions.slideIn = function () {
    var endPos = F._getPosition(true);

    endPos.left = (parseInt(endPos.left, 10) - 200) + 'px';
    endPos.opacity = 0;

    F.wrap.css(endPos).show().animate({
      left: '+=200px',
      opacity: 1
    }, {
      duration: F.current.nextSpeed,
      complete: F._afterZoomIn
    });
  };

    // Current gallery item - fly from center to the right
  F.transitions.slideOut = function () {
    F.wrap.removeClass('fancybox-opened').animate({
      left: '+=200px',
      opacity: 0
    }, {
      duration: F.current.prevSpeed,
      complete: function () {
        $(this).trigger('onReset').remove();
      }
    });
  };

}(jQuery, jQuery.fancybox));

$.fn.cycleOffers = function (options) {
  var settings = $.fn.extend({
    cycleInterval: 3500,
    startDelay: 0
  }, options);
  var offersList = this;
  var offers = offersList.find('li');
  var offersCount = offers.length;
  var count = 0;

  function cycle() {
    offers.eq(count)
      .slideDown()
      .delay(settings.cycleInterval)
      .slideUp(function () {
        $(this)
          .appendTo(offersList)
          .css('display', 'block');
        count = (count < offersCount - 1) ? count + 1 : 0;
        cycle();
      });
  }

  if (settings.startDelay) {
    window.setTimeout(function(){
      cycle();
    }, settings.startDelay);
  } else {
    cycle();
  }

};

$.fn.message = function (options) {
  var settings = $.fn.extend({
    hasCloseButton : true,
    removeOnClose: false
  }, options);
  if (settings.hasCloseButton) {
    this.each(function () {
      var message = $(this),
        closeBtn = $(this).find('.msg-close'),
        dismissMessage = function () {
          if (settings.removeOnClose) {
            message.fadeOut(340, function () {
              $(this).remove();
            });
          } else {
            message.addClass('hidden');
          }
          if (settings.whenClosed) {
            settings.whenClosed();
          }
        };
      if (closeBtn.length) {
        closeBtn.on('click', dismissMessage);
      }
    });
  }
};

$.fn.scroll = function (options) {
  var settings = $.fn.extend({
    speed: 600,
    animation: 'swing',
    offset: options.offset || 25
  }, options);

  $('html, body').stop(true).animate({
    scrollTop : $(this).offset().top - settings.offset
  }, settings.speed, settings.animation);
};

$.fn.imageFlip = function () {
  this.each(function () {
    var flip = $(this),
      image = flip.find('img'),
      forwardFlip = function () {
        var src = image.data('src');
        var backSrc = image.data('altSrc');
        if (src !== backSrc) {
          image.attr('src', backSrc);
        }
      },
      backwardFlip = function () {
        var src = image.data('src');
        var backSrc = image.data('altSrc');

        if (image.data('src') !== backSrc) {
          image.attr('src', src);
        }
      };
    flip.on({
      'mouseover': forwardFlip,
      'mouseout': backwardFlip
    });
  });
};

$.fn.modal = function () {
  var showModal = function (event) {
    var target = $( $(this).data('target') );
    event.preventDefault();
    $('body').css('overlfow-y', 'hidden');
    if( target.length ) {
      target
				.stop(true)
				.fadeIn(200)
				.on('click', hideModal)
				.on('click', '.modal--close', hideModal)
				.on('click', '.modal--body', function (event) {
  event.stopPropagation();
});
    }
  };
  var hideModal = function () {
    $('.modal')
			.stop(true)
			.fadeOut(200);
    $('body').css('overlfow-y', 'hidden');
  };
  this.each(function () {
    $(this).on('click', showModal);
  });
};

$.fn.tooltip = function () {
  // var settings = $.fn.extend({
  //     direction: 'top'
  //   }, options),
  function tooltipOn() {
    var el = $(this),
      tip = el.data('say'),
      tooltip = el.find('.tooltip');
    if (!tip || tip == '') {
      return false;
    }
    if (tooltip) {
      tooltip.remove();
    }
    $('<div></div>')
          .html(tip)
          .addClass('tooltip')
          .prependTo(el);
    if (el.outerWidth() < 240) {
      el
        .find('.tooltip')
        .css({
          'width': el.width() * 2,
          'left': '-50%'
        });
    }
  }

  function tooltipOff() {
    var el = $(this);
    var tooltip = el.find('.tooltip');
    tooltip.remove();
  }

  this.each(function () {
    var self = $(this);
    self.on('mouseover focus', tooltipOn);
    self.on('mouseout blur', tooltipOff);
  });
};

$.fn.featuredSlideshow = function () {
  var self = $(this);
  var featuredSet = self.find('.featured-items-wrapper');
  var nextArrow = self.find('.nav-arrow.next');
  var prevArrow = self.find('.nav-arrow.prev');

  function slidePrev() {
    var curr = self.find('.current');
    var prev = curr.prev();
    curr.removeClass('current').hide();
    if (prev.length !== 0) {
      prev.addClass('current').fadeIn(350);
    } else {
      featuredSet.last().addClass('current').fadeIn(350);
    }
  }

  function slideNext() {
    var curr = self.find('.current');
    var next = curr.next();
    curr.removeClass('current').hide();

    if (next.length !== 0) {
      next.addClass('current').fadeIn(350);
    } else {
      featuredSet.first().addClass('current').fadeIn(350);
    }
  }

  function initArrows() {
    prevArrow.on('click', slidePrev);
    nextArrow.on('click', slideNext);
  }

  if (featuredSet.length < 2) {
    nextArrow.hide();
    prevArrow.hide();
  } else {
    featuredSet.hide();
    featuredSet
			.first()
			.addClass('current')
			.show();
    initArrows();
  }
};

$.fn.stickyFooter = function () {
  var footer = $(this);
  var isResized = false;

  function makeFooterSticky() {
    $('body').css('margin-bottom', footer.outerHeight() + 20);
  }

  makeFooterSticky();

  $(window).on('resize', function () {
    isResized = true;
  });

  setInterval(function () {
    if (isResized) {
      isResized = false;
      makeFooterSticky();
    }
  }, 200);
};

var mobileSearch = (function () {
  return {
    init: function () {
      var searchBox = $('#searchbox');
      var searchTextField = $('#search_textfield');
      var searchIcon = $('#search_icon');
      var searchClose = searchBox.find('.dismiss-search');

      function toggleMobileSearch(event) {
        event.stopPropagation();
        searchBox.toggleClass('visible');
        searchTextField.focus();
      }

      function hideSearchBox() {
        searchBox.removeClass('visible');
      }

      searchIcon.on('click', toggleMobileSearch);
      searchBox.on('click', function (event) {
        event.stopPropagation();
      });
      searchClose.on('click', hideSearchBox);
    }
  };
}());

/* global $ */
var menuShopBy = (function () {
  var _colorToggles = '#menu_reset, #cMenu, #colorSB',
    _brandToggles = '#bMenu, #brandSB',
    _collectionToggles = '#colMenu, #collectionSB',
    _resetShopby = function () {
      $('#SBColorPanel').hide();
      $('#SBBrandPanel').hide();
      $('#SBCollectionPanel').hide();
      $('#colorSB').addClass('active');
      $('#brandSB').removeClass('active');
      $('#collectionSB').removeClass('active');
      
      $('#SBarrow')
				.stop(true)
				.animate({
				  'margin-left': 0
				}, 250);
    },
    _shopByToggle = function(type) {
      _resetShopby();
      if (type === 'color') 
      {
        $('#SBBrandPanel').hide();
        $('#SBCollectionPanel').hide();
        $('#SBColorPanel').show().css('background-color', 'red');
        $('#SBarrow')
					.stop(true)
					.animate({
					  'margin-left': '-40px'
					}, 250);
        $('#colorSB').addClass('active');
      }
      if (type === 'brand')
	  {
		  $( '#SBColorPanel' ).hide();
		  $('#SBCollectionPanel').hide();
		  $( '#SBBrandPanel' ).show();
		  $( '#brandSB' ).addClass( 'active' );
		  $( '#colorSB' ).removeClass( 'active' );
		  $('#collectionSB').removeClass('active');
		  $( '#SBarrow' )
			  .stop( true )
			  .animate( {
				  // number is hardcoded
				  // until I find a way to dynamically detect its
				  // position
				  'margin-left': 15
			  }, 250 );
	  }
      if (type === 'collection' ) {
          $( '#SBColorPanel' ).hide();
		  $('#SBCollectionPanel').show();
		  $( '#SBBrandPanel' ).hide();
		  $( '#brandSB' ).removeClass( 'active' );
		  $( '#colorSB' ).removeClass( 'active' );
		  $('#collectionSB').addClass('active');
		  $( '#SBarrow' )
			  .stop( true )
			  .animate( {
				  // number is hardcoded
				  // until I find a way to dynamically detect its
				  // position
				  'margin-left': 84
			  }, 250 );  
      }
      
    };

  return {
    init : function () {
      $(_brandToggles).on('mouseover.shopby', function () {
        _shopByToggle('brand');
      });
      $(_colorToggles).on('mouseover.shopby', function () {
        _shopByToggle('color');
      });
      $(_collectionToggles).on('mouseover.shopby', function () {
        _shopByToggle('collection');
      });
    }, 
  };
}());

$.fn.mobileMenu = function (options) {
  var menu = $(this);
  var settings = $.fn.extend({
    mobileWidth: 800
  },
	options);
  var menuObj = {
    openIcon : $('#menu_icon'),
    closeIcon: $('#mobile_close'),
    dropdown: menu.find('.mobile-dropdown'),
    overlay: menu.find('.mobile-overlay'),
    subMenu: menu.find('.menu'),
    content: menu.find('.content'),
    topLabel: menu.find('.label'),
    drawerOpened: false,
    screenWidth: settings.mobileWidth
  };

  function menuDrawer() {
    if (menuObj.drawerOpened) {
      menuObj.overlay.addClass('hidden');
      menuObj.dropdown.removeClass('slide-left');
      menuObj.drawerOpened = false;
      $('body').css('overflow-y', 'auto');
    } else {
      menuObj.overlay.removeClass('hidden');
      menuObj.dropdown.addClass('slide-left');
      menuObj.drawerOpened = true;
      $('body').css('overflow-y', 'hidden');
    }
  }

  function showMobileContents() {
    var selectedContent = $(this).next(),
      allContent = menuObj.content,
      isHiddenContent = selectedContent.hasClass('hidden-mobile');
    if (isHiddenContent) {
      allContent.addClass('hidden-mobile');
      selectedContent.removeClass('hidden-mobile');
    } else {
      selectedContent.addClass('hidden-mobile');
    }
  }

  function toggleDropdownMenu(width) {
    if (width < settings.mobileWidth) {
      menuObj.content.addClass('hidden-mobile');
    } else {
      if (menuObj.content.hasClass('hidden-mobile')) {
        menuObj.content.removeClass('hidden-mobile');
      }
    }
  }

  menuObj.topLabel.on('click', showMobileContents);
  menuObj.openIcon
			.add(menuObj.closeIcon)
			.add(menuObj.overlay)
			.on('click', menuDrawer);
  $(window).on('resize', function () {
    var updatedViewport = $(this).innerWidth(); // recalculate window width on resize
    toggleDropdownMenu(updatedViewport);
  })

  .on('load', function () {
    toggleDropdownMenu(window.innerWidth);
  });
};
/* global $, printWarning, Modernizr */
/*
    Color swatch hover effect
*/

$.fn.swatch = function (options) {
	// if(!$(this).hasClass('color_not_available')) {
     var settings = $.extend({}, options);
     var imgRegex = /[_]([A-Z0-9]{3,}(?=\.|_))/;
     var colorNamePrint = settings.altPrintName || $('.swatch-color-name');
     var initialColorName = colorNamePrint.text();
     this.each(function() {
        var primaryImage = settings.altPrimaryImage || $('.js-primary-image');
        var swatch = $(this);
        var swatchData = {
           altered: false,
           isSelected: swatch.hasClass('selected'),
           colorName: swatch.attr('data-color-name'),
           colorId: swatch.attr('data-color-id'),
        };
        var swatchOver = function() {
           var newPrimaryImageSrc = '';
           if(swatchData.colorId) {
              swatchData.altered = true;
              newPrimaryImageSrc = primaryImage
                 .attr('src')
                 .replace(imgRegex, '_' + swatchData.colorId);
              primaryImage.attr('src', newPrimaryImageSrc);
              primaryImage.on('error', function() {
                 if($('#multiple_image').length && !settings.altPrimaryImage) {
                    $(this).attr('src', $('.thumb-image.selected').find('img').attr('data-base-image-src'));
                 } else {
                    $(this).attr('src', $(this).attr('data-init-src'));
                 }
              });
           }
           if(colorNamePrint.length) {
              colorNamePrint.text(swatchData.colorName);
           }
        };
        var swatchOut = function() {
           var currSwatchColorId = $('.swatch.selected').attr('data-color-id');
           var currImageSrc = '';
           if(!swatchData.isSelected && swatchData.altered) {
              currImageSrc = primaryImage.attr('src').replace(imgRegex, '_' + currSwatchColorId);
              primaryImage.attr('src', currImageSrc);
              swatchData.altered = false;
           }
           if(colorNamePrint.length) {
              colorNamePrint.text(initialColorName);
           }
        };
        swatch.on({
           'mouseover': swatchOver,
           'mouseout': swatchOut
        });
     });
  // }
};

$.fn.sizeControls = function () {
  var sizeGrid = $('#size_grid');
  var selectedSize = $('#size_grid').find('.selected');
  var fitOptions = $('#fit_options');
  var productUnavailable = $('#product_unavailable').length;
  var availableSizes = sizeGrid.find('li:not(.out-of-stock)');
  var os = $('#os'); // one size
  var p = {}; // pricing object

    /*
        Calculate savings (MSRP - Price)
        Show savings if savings are more than 25c
        Otherwise, hide.
    */
  var calculateSavings = function (p) {
    var savings = (p.msrp - p.price).toFixed(2);
    if (savings > 0 && savings > 0.25) {
      $('#savings').removeClass('hidden');
      $('#you_save .price').text(savings);
      $('#msrp_row .price').text(p.msrp);
    } else {
      $('#savings').addClass('hidden');
    }
  };
    /*
        Toggle size selection
        1. Update 'selected' class on click
        2. Update price
        3. Re-calculate savings
    */
  var toggleSizesOnGrid = function (event) {
    event.preventDefault();
    var self = $(this);
    var size = self.parent();
    var sizeName = self.text();
    var upc = size.data('upc');
    p.price = size.data('price');
    p.msrp = size.data('msrp');

    $('#upc').val(upc);
    $('#product_info').find('.product-price').text(p.price);

    $('#size-error').addClass('hidden');
    sizeGrid.find('.size').removeClass('selected');
    $('#selected_size').html(sizeName);  // render size name in html
    size.addClass('selected');
    calculateSavings(p);
  };
    /*
        Toggle sizes on 'select' elements
        Essentially, does the same thing as 'toggleSizesOnGrid',
        but without extra visual clues
    */
  var toggleSizesOnSelect = function () {
    var size = $(this).find('option:selected');
    p.price = size.data('price');
    p.msrp = size.data('msrp');
        //calculateSavings(p);
    $('.ql').find('.product-price').text(p.price);
  };

  fitOptions.on('click', 'li', function () {
    if ($(this).siblings().length) {
      $(this).siblings().removeClass('selected');
    }
    $(this).addClass('selected');
  });

    /*
        If product is out of stock remove from view:
        - 'Add to cart button'
        - 'Height/Size selector'
        - 'Qty selector'
    */
  if (productUnavailable) {
    $('#size_container .label:first, #add_to_cart, #size_grid, #fit_options, #qty_container').remove();
  }

    // if all sizes are out of stock and product doesn't have one size
  if (availableSizes.length === 0 && os.length === 0) {
    $('#size_container .label:first, #size_container span:first, #add_to_cart, #size_grid, #fit_options, #qty_container').hide();
    $('<p></p>').attr({
      'id' : 'product_unavailable',
      'class' : 'out-of-stock',
    }).html('Out of stock').appendTo('#size_container');
  }

  if (os.length > 0) {
    $('#size_container').hide();
  }

  if ($('#color_container').children().length === 0) {
    $('#color_container').hide();
  }

    /* On initial page load, check if size is selected
       Calculate savings again
    */
  if (selectedSize.length) {
    p.price  = selectedSize.data('price');
    p.msrp = selectedSize.data('msrp');
    calculateSavings(p);
  }
  sizeGrid.on('click', '.size a', toggleSizesOnGrid);
  $('.ql')
        .find('.sz-selector')
        .on('change', toggleSizesOnSelect);
};


/*
    Validate size selection.
    Do not allow user to submit to cart
    unless size is selected by user or on load.
*/
$.fn.validateSzEmbroidery = function (options) {
  var settings = $.fn.extend({
    validate: false
  }, options);

  var $productForm = $(this),
    $sizeGrid = $('#size_grid'),
    $sizeError = $('#size-error'),
    $embroidery = $('#embroidery'),
    $embroideryError = $('#embroidery-error'),
    $embroideryRequired = $embroidery.find('.required');
  function showSizeError() {
    $sizeError.removeClass('hidden');
  }
  function hideSizeError() {
    $sizeError.addClass('hidden');
  }
  function showEmbroideryError() {
    $embroideryError.removeClass('hidden');
  }
  function hideEmbroideryError() {
    $embroideryError.addClass('hidden');
  }
  function runSizeValidation() {
    var sizeChosen = $sizeGrid.find('.selected').length;
    return sizeChosen ? true : false;
  }
  function runEmbroideryValidation() {
    var embroideryReqFields = $embroidery.find('.required');
    embroideryReqFields.each(function() {
      var self  = $(this);
      if (self.val() === '') {
        showEmbroideryError();
        self.addClass('has-error');
      }
    });
    if ($embroidery.find('.has-error').length === 0) {
      hideEmbroideryError();
      return true;
    } else {
      return false;
    }
  }
  function runValidation() {
    var sizeValid = false,
      embroideryValid = false;

    if ($sizeGrid.length) {
      sizeValid = runSizeValidation();
    } else {
            // validate size OK if there is no size to select to allow form to submit
      sizeValid = true;
    }
    if ($embroidery.length && $embroidery.find('.required').length) {
      // validate embroidery OK if there is no embroidery to allow form to submit
      embroideryValid = runEmbroideryValidation();
    } else {
      embroideryValid = true;
    }
    return {
      sizeValid: sizeValid,
      embroideryValid: embroideryValid
    };
  }

  if ($embroidery.length) {
    if ($embroideryRequired.length) {
      $embroideryRequired.each(function() {
        var $emb = $(this);
        function isEmbroideryValid() {
                    // if invalid add red border around it
          if ($emb.val() !== '') {
            $emb.removeClass('has-error');
          }
                    // check if any of the required fields are still missing
                    // if all clear, hide embroidery error
          if ($embroidery.find('.has-error').length === 0) {
            hideEmbroideryError();
          }
        }
        $emb.on('change', isEmbroideryValid);
      });
    }
  }

    // run validation only if size grid or embroidery is present
    // otherwise, submit the form without validation
  if (settings.validate === true && $sizeGrid.length || $embroidery.length) {
    $productForm.on('submit', function(event) {
            // runValidation() will return an object
      var vObj = runValidation();
      if (vObj.sizeValid && vObj.embroideryValid) {
        hideSizeError();
        hideEmbroideryError();
        return true;
      }
      else if (!vObj.sizeValid) {
        showSizeError();
                // scroll size grid into view to show error
        $sizeError.scroll({offset: 35, speed: 200});
        event.preventDefault();
      }
      else if(!vObj.embroideryValid) {
        showEmbroideryError();
        hideSizeError();
        $embroideryError.scroll({offset: 35, speed: 200});
        event.preventDefault();
      } else {
        event.preventDefault();
        printWarning('This should not happen.');
      }
    });
  }
};

/* Product Page thumbnails logic */
$.fn.thumbnails = function (options) {
  var opts = $.fn.extend(
    {
      self: this,
      thumbnails: this.find('.thumb-image'),
      thumbnailsWrapper: $('<div></div>'),
      didWindowResize: false,
      stickyThumbs: true,
      checkHeightInterval: 300,
      fallbackEnabled: true,
      responsive: true
    }, options
    );

    // 1. All thumb images are wrapped inside a container
    // and using CSS positioned at the top left of product page
  var wrapperFunc = function () {
    opts.thumbnailsWrapper
            .attr('id', 'multiple_image')
            .prependTo('#product_images');
    opts.thumbnails.prependTo(opts.thumbnailsWrapper);
  };
    // 2. Ensure that the height of thumbnail wrapper is
    // consistent with main product image.
  var stickyThumbsFunc = function () {
    $('#multiple_image')
            .css('height', $('#single_image').outerHeight() || '500px');
    opts.didWindowResize = false;
  };
    // Images that are not found will be replaced with 'placeholder'
  var deepImageFallbackFunc = function () {
    opts.thumbnails
      .find('img')
      .each(function () {
        var img = $(this);
        if (img.attr('src') === '') {
          if (img.data('baseImageSrc') !== '') {
            img.attr('src', img.data('baseImageSrc'));
          } else if (img.data('zoomImage') !== '') {
            img.attr('src', img.data('zoomImage'));
            img.data('baseImageSrc', img.data('zoomImage'));
          } else {
                        // Fallback
            img.attr('src', '/meta/pie/0004/0004/0777/misc/img_not_available.jpg');
            img.off('mouseover click');
                        // or simply remove empty image
                        // img.remove();
          }
        }
      });
  };
    // 4. On thumbnail hover or click -- update current product image.
  var imageSwapFunc = function (event) {
    var mainImage = $('.js-primary-image'),
      thumb = $(this),
      thumbImage = thumb.find('img'),
      baseSrc = thumbImage.data('baseImageSrc'),
      baseID = thumbImage.data('baseImageId'),
      zoomSrc = thumbImage.data('zoomImage'),
      currSrc,
      isThumbnailSelected;
    if (baseSrc && baseSrc !== '') {
      if (event.type === 'click') {
        opts.thumbnails.removeClass('selected');
        thumb.addClass('selected');

        mainImage.attr('src', baseSrc);
        mainImage.data('baseImageSrc', baseSrc);
        mainImage.data('baseImageId', baseID);

        if (zoomSrc) {
          $('#single_image').find('a').attr('href', zoomSrc);
          mainImage.data('zoomImage', zoomSrc);
          mainImage.elevateZoom({
            easing: true,
            borderSize: 1,
            borderColour: '#eee',
            lensColour: '#eee',
            cursor: 'pointer',
            zoomWindowHeight: parseInt($('#single_image').find('img').data('height'), 10),
            zoomWindowWidth: parseInt($('#single_image').find('img').data('height'), 10)
          });
        } else {
          $('#single_image').find('a').attr('href', baseSrc);
          mainImage.data('zoomImage', baseSrc);
          mainImage.elevateZoom({
            easing: true,
            borderSize: 1,
            borderColour: '#eee',
            lensColour: '#eee',
            cursor: 'pointer',
            zoomWindowHeight: parseInt($('#single_image').find('img').data('height'), 10),
            zoomWindowWidth: parseInt($('#single_image').find('img').data('height'), 10)
          });
        }
      } else if (event.type === 'mouseover') {
        if (!thumb.hasClass('selected')) {
          mainImage.attr('src', baseSrc);
          mainImage.data('baseImageSrc', baseSrc);
          mainImage.data('baseImageId', baseID);
        }
      } else if (event.type === 'mouseout') {
        currSrc = $('.thumb-image.selected img').data('baseImageSrc');
        isThumbnailSelected = thumb.hasClass('selected');
        if (!isThumbnailSelected) {
          mainImage.attr('src', currSrc);
        }
      }
    }
  };

    // 5. On mobile viewports, all thumbnail-images are collected
    // and appended to current main image container
    // slideshow controls are then applied to implement touch swipe.
    // Note: this will not work on desktop browsers that are just simply
    // resized.
  var mobileViewFunc = function () {
    var initViewportWidth = window.innerWidth,
      initViewportHeight = window.innerHeight,
      regexThumb = /\/060{1}|\/040{1}|\/080{1}/,
      mobileSlideshowContainer = $('#single_image').find('.cycle-through'),
      mobileSlideshowCtrls = $('#single_image').find('.slide-ctrl');
        // Mobile view for smartphones
    if (Modernizr.touch) { // check if this is a touch enabled device
            // check viewport width; also check if device is in landscape orientation
      if (initViewportWidth <= 800 || initViewportWidth > initViewportHeight && initViewportWidth < 1024) {
                /*
                    1. get all images from multiple image container
                    2. delete current single image (avoids duplication)
                    3. destroy multiple image container on completion
                */
        $('.zoomContainer').hide();

        if (opts.thumbnails.length > 1) { // swiping makes no sense if only one image is present.
          mobileSlideshowContainer.find('.fancy').remove();
          $('.thumb-image img').each(function () {
            $(this)
                            .attr('src', $(this).attr('src').replace(regexThumb, '/400'))
                            .appendTo(mobileSlideshowContainer);
          });

          mobileSlideshowContainer.find('img')
                        .each(function () {
                          $(this).wrap('<div></div>');
                        })
                        .end().slick({
                          dots: false,
                          slidesToShow: 1,
                          slidesToScroll: 1,
                          mobileFirst: true,
                          swipeToSlide: true,
                          touchMove: true,
                          adaptiveHeight: true,
                          prevArrow: $('.slide-prev'),
                          nextArrow: $('.slide-next')
                        });
          mobileSlideshowCtrls.removeClass('hidden');
        }
      }
    }
  };
  var initMobileViewFunc = function () {
    $(window).on('load', mobileViewFunc);
        // hide zoom container
    $(window).on('resize', function () {
      var current_vw = window.innerWidth;
      if (current_vw <= 800) {
        $('.zoomContainer').hide();
      } else {
        $('.zoomContainer').show();
      }
    });
  };
    // Fire up event on thumbnail images
  opts.self.on('mouseover mouseout click', '.thumb-image', imageSwapFunc);
    // Enable image fallback
  if (opts.thumbnails.length && opts.fallbackEnabled) {
    deepImageFallbackFunc();
  }
    // Wrapper function
  if (opts.thumbnails.length) {
    wrapperFunc();
    if (opts.stickyThumbs) {
      stickyThumbsFunc();
      $(window).on('resize', function () {
        opts.didWindowResize = true;
      });
      setInterval(function () {
        if (opts.didWindowResize) {
          opts.didWindowResize = false;
          stickyThumbsFunc();
        }
      }, opts.checkHeightInterval);
    }
  }
    // Mobile (swipe) functionality
  if (opts.thumbnails.length && opts.responsive) {
    initMobileViewFunc();
  }
};
/* global $, toastr, printError, printWarning, quicklook (updated by HY 2017-01-23) */

var quicklook = (function () {
  'use strict';

  function initToastrOptions() {
    toastr.options.positionClass = 'toast-top-full-width';
    toastr.options.closeButton = true;
    toastr.options.escapeHtml = true;
    toastr.options.preventDuplicates = true;
  }

  function keepToasterInView() {
    toastr.options.timeOut = 0;
    toastr.options.extendedTimeOut = 0;
  }


  function closeToasterAfter(sec, extSec) {
    toastr.options.timeOut = sec;
    toastr.options.extendedTimeOut = extSec;
  }

  function ajaxFormSubmit(event) {
    event.preventDefault();

    function updateItemCount(count) {
//      $('#cart').text(count + ' item(s)');
      $('#cart').text(count);
    }

    function checkInventoryLevels(response) {
      var inventoryData, cartCount;
      if (response) {
        cartCount = response.cart_count;
        inventoryData = response.item_max_quantity;
        for (var i = 0; i < inventoryData.length; i++) {
          if (inventoryData[i].status === 'FAILED') {
                            // inventory limits reached
            if (inventoryData[i].max_quantity === -1) {
              keepToasterInView();
              toastr.warning('We are sorry, but you already exceeded number of items for this product.');
            }
                            // inventory limits about to be reached. Adjust to available amount.
            else if (inventoryData[i].max_quantity > 0) {
              keepToasterInView();
              toastr.warning('We are sorry, but we do not carry that many items. Quantities have been adjusted to recommended amount - ' + inventoryData[i].max_quantity + '.');
            }
                            // server error. should not get to this point.
            else if (inventoryData[i].max_quantity === 0) {
              printError('Quantity control error.');
              return null;
            }
          } else {
                            // item successfully added to cart (no inventory threshold)
            closeToasterAfter(2000, 5000);
            toastr.success('Item added to cart');
                            //showSuccessMessage();
          }
                        // update item count at the top corner
          if (cartCount) updateItemCount(cartCount);
        }
      } else {
        printWarning('[Quantity Control]: Response is undefined or not JSON');
      }
    }

    var form = $(this);
    var sendData = form.serialize();
    var endPoint = form.attr('action');
    $.ajax({
      url: endPoint,
      data: sendData,
      type: 'GET',
      timeout: 5000,
      beforeSend: function () {
        $('#quick_add_btn')
                        .addClass('btn--disabled disabled')
                        .val('Adding...');
      },
      success: function (response) {
        form.hide();
        $.fancybox.close(true);

                    // Check if cart quantity exceeded inventory level
        checkInventoryLevels(response);
      },
      error: function(request, errorType, errorMessage) {
        printError(errorType + ': ' + errorMessage);
      },
      complete: function () {
        $('#quick_add_btn')
                        .removeClass('btn--disabled disabled')
                        .val('Add To Cart');
      }
    });
  }

  function popup(event) {
    event.preventDefault();
    var url = $(this).attr('href');
    $.fancybox({
      href: url + '?quick_look=1&ajax=1',
      padding: 18,
      margin: 65,
      autoSize: true,
      maxWidth: 900,
      autoWidth: true,
      autoHeight: true,
      minHeight: 420,
      type: 'ajax',
      wrapCSS: 'ql',
      closeEffect: 'elastic',
      scrolling: 'no',
      afterShow: function () {
        // After fancybox loads:
        // 1. Initialize size controls
        $('.ql').sizeControls();
        // 2. Color swatch
        $('.ql').find('.swatch').swatch({
          altPrintName: $('.ql-swatch-color-name'),
          altPrimaryImage: $('.js-diff-primary')
        });
      },
      helpers: {
        overlay: {
          showEarly: true
        }
      }
    });
    $.fancybox.update();
  }

  return {
    init: function () {
      initToastrOptions();
      var hasQL = $('.quick-look-container').length;
      if (hasQL) {
        // popup window
        var qlLink = $('.quick-look');
        qlLink.each(function () {
          $(this).find('a').on('click', popup);
        });
                // Ajax add to cart on form submit;
        $(document).on('submit', '#quick_form', ajaxFormSubmit);
      }
    }
  };
}());

$(document).ready(function () {
	// Load the rest of JavaScript files
	// ...
	/* global $, printError */
	(function () {
	  'use strict';
	  var $validator_form = $('.validator--form'),
	    rgxlib = {
	      password: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d$@$!%*?&]{8,}$/i,
	      email: /^[-a-z0-9~!$%^&*_=+}{\'?]+(\.[-a-z0-9~!$%^&*_=+}{\'?]+)*@([a-z0-9_][-a-z0-9_]*(\.[-a-z0-9_]+)*\.(aero|arpa|biz|com|coop|edu|gov|info|int|mil|museum|name|net|org|pro|travel|mobi|[a-z][a-z])|([0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}))(:[0-9]{1,5})?$/i,
	            // zip: /(^\d{5}$)|(^\d{5}-\d{4}$)/,
	      zip: /(^\d{5}$)/,
	      cc: /^[\d\s-]+$/,
	      is_visa : /^4[0-9]{12}(?:[0-9]{3})?$/,
	      is_master_card : /^5[1-5][0-9]{14}$/,
	      is_discover : /^6(?:011|5[0-9]{2})[0-9]{12}$/
	            //is_amex : /^3[47][0-9]{13}$/
	            //is_diners_c : /^5[1-5][0-9]{14}$/
	    },
	    is_new_customer = $('#is_new_customer'),
	    rgr_password = $('#quick_register');
	
	    // A quick fix for dependancy validation.
	    // Until I figure out how to add dependancy to validator module, lets just leave it as is.
	  is_new_customer.on('change', function(){
	    var c = $(this).is(':checked');
	    if (c) {
	      rgr_password.addClass('validator--required');
	    } else {
	      rgr_password.removeClass('validator--required');
	    }
	  });
	
	    //  cc date validation:
	  var creditCardExpired = function () {
	    var currDate = new Date(),
	      expDate = new Date(),
	      ccYear = parseInt( $('.validator--ccy').val() ),
	      ccMonth = parseInt( $('.validator--ccm').val() );
	    expDate.setFullYear(ccYear, ccMonth, 1);
	    return expDate < currDate;
	  };
	    // shipping error validation
	  var shippingSelected = function () {
	    return $('.shipping-method-item:not(.hidden)').find('.rdo-ship:checked').length;
	  };
	
	  var throwShippingError = function () {
	    $('.js-shipping-error').removeClass('hidden').message();
	    $('.js-shipping-error').scroll({speed: 200});
	  };
	    // Custom validation methods
	    /*
	        NOTE: In order for custom message (on required) to work, please supply data-title attribute to input element that is being validated.
	        Otherwise, generic message will be shown as fallback.
	        DO NOT use 'title' attribute as it will overwrite any custom or generic messages.
	    */
	  $.validator.addMethod('validate_required', $.validator.methods.required, function(value, element) {
	    var custom_title = $(element).attr('data-title');
	    return (typeof custom_title !== 'undefined') ? custom_title + ' is required' : 'This field can\'t be empty.';
	  });
	  $.validator.addMethod('validate_email', function (value) {
	    return rgxlib.email.test(value);
	  }, 'Please provide a valid email');
	  $.validator.addMethod('validate_conf_email', function () {
	    return $('.validator--email').val() === $('.validator--email.validator--confemail').val() ? true : false;
	  }, 'Emails don\'t match');
	  $.validator.addMethod('validate_password', function (value) {
	    return rgxlib.password.test(value);
	  }, 'Your password must be at least 8 characters and contain 1 number');
	  $.validator.addMethod('validate_zip', function (value) {
	    return rgxlib.zip.test(value);
	  }, 'Invalid US zip code');
	  $.validator.addMethod('validate_cc', function (value) {
	    return rgxlib.cc.test(value);
	  }, 'Invalid credit card format');
	  $.validator.addMethod('validate_confirm_password', function () {
	        // We might run into an issue here if we have two or more forms requiring passwords.
	        // Right now lets assume we only have one form with passwords on tha page.
	    return $('.validator--password').val() === $('.validator--confpassword').val() ? true : false;
	  }, 'Passwords do not match');
	
	    // Attach validation classes to input fields (ie validator--**) to ensure proper validation.
	    // do not use 'required' attribute. Unexpected results might occur. It's also redundant.
	
	  $.validator.addClassRules({
	    'validator--required': {
	      validate_required : true
	    },
	    'validator--email': {
	      validate_email : true
	    },
	    'validator--confemail': {
	      validate_conf_email : true
	    },
	    'validator--password': {
	      validate_password : true
	    },
	    'validator--confpassword': {
	      validate_confirm_password : true
	    },
	    'validator--zipcode': {
	      validate_zip : true
	    }
	  });
	
	  if ($validator_form.length) {
	    $validator_form.each(function() {
	      var validatorForm = $(this);
	      validatorForm.validate({
	        ignore: '.novalidate, :hidden',
	        errorClass: 'has-error',
	        errorElement: 'span',
	        errorPlacement: function (error, element) {
	          if ($(element).hasClass('validator--nomsg')) {
	            return;
	          } else {
	            error.appendTo(element.siblings('label'));
	          }
	        },
	        submitHandler: function (form) {
	          var isExpired, isShippingSelected;
	          if ($(form).hasClass('validator--form-cc')) {
	            isExpired = creditCardExpired();
	            if (isExpired) {
	                            //event.preventDefault();
	              try {
	                $('#cc_expiry_error').removeClass('hidden').message();
	              } catch (error) {
	                printError(error);
	                if(error instanceof TypeError) {
	                  printError('Seems like cc expiry error element is of wrong type.');
	                }
	                if(error instanceof ReferenceError) {
	                  printError('Seems like cc expiry error element doesn\'t exist.');
	                }
	              }
	            } else {
	              $('#cc_expiry_error').addClass('hidden');
	              try {
	                isShippingSelected = shippingSelected();
	                if (isShippingSelected) {
	                  $('.validator--submit').addClass('disabled').text('Processing...');
	                  form.submit();
	                } else {
	                  throwShippingError();
	                }
	              } catch (err) {
	                printError(err.name + ':' + err.message);
	              }
	            }
	          }
	          else if ($(form).hasClass('validator--pd')) {
	            try {
	              var isTosChecked = $('#tos').prop('checked');
	              if (!isTosChecked) {
	                $('#pd_tos_error').removeClass('hidden');
	              } else {
	                $('#pd_tos_error').addClass('hidden');
	                form.submit();
	              }
	            } catch(error) {
	              printError('validation error occured.');
	            }
	          } else {
	            $(form).find('input[type=submit], button[type=submit]').addClass('disabled');
	            form.submit();
	          }
	
	        }
	      });
	    });
	  }
	
	    // This validator form type does not append labels, but highlights invalid fields.
	    // Great for inline forms that dont need any extra markup.
	  if ( $('.validator--form-inline').length ) {
	    $('.validator--form-inline').validate({
	      validClass: 'has-success',
	      errorClass: 'has-error',
	      errorPlacement: function () {},
	      submitHandler: function (form) {
	        var validator_submit_btn = $('.validator--submit');
	        if (validator_submit_btn.length) {
	          $('.validator--submit').addClass('disabled').text('Processing...');
	        }
	        form.submit();
	      }
	    });
	  }
	}());
	
	/* global $, printError, console, window */
	(function() {
		'use strict';
		/*
			Do not search on empty query.
		*/
		var canSearch = function(event) {
			var searchField = $('#searchbox_textfield'),
			    searchQuery = searchField.val();
			if(searchQuery === '' || typeof searchQuery === 'undefined') {
				// Search field is empty, don't search.
				event.preventDefault();
				searchField.focus();
			} else {
				// Search field is not empty, allow search.
			}
		};
		$('#searchForm').on('submit', canSearch);
		/*
			Tophat promotions cycle
			=> ../modules/interface.js
		*/
		
		// general offers cycle
		if($('.offers').length) {
			$('.offers').cycleOffers({
				cycleInterval : 4000
			});
		}
		
		// text ads cycle
		if($('.offers_text').length) {
			$('.offers_text').cycleOffers({
				cycleInterval : 4000,
				startDelay    : 2000
			});
		}
		/*
			Message popups with close buttons
			=> ../modules/interface.js
		*/
		$('.msg').message({
			hasCloseButton : true
		});
		/*
			Image Flip
			(front to back, back to front)
			=> ../modules/interface.js
		*/
		if($('.flip').length) {
			$('.flip').imageFlip();
		}
		/*
			Fancybox custom code.
			see ../modules/interface.js for additional source code
		*/
		$('.fancy').fancybox({
			openMethod  : 'dropIn',
			openSpeed   : 250,
			closeMethod : 'dropOut',
			closeSpeed  : 150
		});
		/*
			Modal windows
			module path => ../modules/interface.js
		*/
		$('.modal--trigger').modal();
		/*
			Shopby menu.
			Switches between color and brand menu
			=> ../modules/navigation.js
		*/
		menuShopBy.init();
		/*
			Mobile search dropdown
			=> ../modules/interface.js
		*/
		mobileSearch.init();
		// (function mobile_search_toggle() {
		//     var $searchbox = $('#searchbox'),
		//         $search_field = $('#searchbox_textfield'),
		//         $search_icon = $('#search_icon');
		
		//     $search_icon.on('click', function (event) {
		//         event.stopPropagation();
		//         $searchbox.toggleClass('visible');
		//         $search_field.focus();
		//     });
		//     $searchbox.on('click', function (event) {
		//         event.stopPropagation();
		//     });
		//     $(document).on('click', function () {
		//         $('.mod-touch #searchbox').removeClass('visible');
		//     });
		
		// }());
		
		/*
			Mobile menu plugin
			Mobile path => ../modules/navigation.js
		*/
		$('#menu').mobileMenu();
		
		/*
			Home page banner carousel
			Using slick plugin: https://github.com/kenwheeler/slick
		*/
		
		$('#slideshow, .slideshow_multiple').find('.banner-wrapper').slick({
			swipe          : false,
			autoplay       : true,
			autoplaySpeed  : 2500,
			speed          : 800,
			arrows         : false,
			// prevArrow     : $('.slide-arrow.prev'),
			// nextArrow     : $('.slide-arrow.next'),
			dots           : true,
			dotsClass      : 'slick-dots',
			slidesToShow   : 1,
			slidesToScroll : 1,
			fade           : true,
			ease           : 'easeInOutBack',
			responsive     : [
				{
					breakpoint : 800,
					settings   : {
						dots           : true,
						dotsClass      : 'slick-dots',
						autoplaySpeed  : 3000,
						fade           : false,
						slidesToShow   : 1,
						slidesToScroll : 1,
						swipe          : true
					}
				}
			]
		});
		/* Featured items slideshow */
		$('#featured_items, .featured_items_multiple').featuredSlideshow();
		
		/* Generic Ajax Calls:
			=> ../modules/ajax.js
		*/
		// 1. Ajax to submit user email to mail list
		ajaxLib.mailList.init();
		
		/*
			Tooltips: currently only showing on top.
			Customization will be added in the next version
			=> ../modules/interface.js
		*/
		$('.has-tooltip').tooltip();
		/*
			Sticky Footer: footer persists at the bottom
			of the page regardless of window height
			=> ../modules/interface.js
		*/
		$('#footer').stickyFooter();
		
		/*
			editable UI
			=> ../modules/edit.js
		*/
		editableUI.init();
		
		/*
			Payroll Deduction AJAX calls shared between profile and checkout pages
			=> ../modules/ajax.js
		*/
		
		var activatePayrollBtn = $('.js-pd-activate');
		
		activatePayrollBtn.on('click.pd_activate', function(event) {
			event.preventDefault();
			try {
				
				var self = $(this);
				var refURL = self.attr('data-refer-url');
				ajaxLib.pd().getDepartmentList(refURL,
					function before() {
						$('#payroll_activate_frm').addClass('attach-loader');
					},
					function success(response) {
						var pdPlans = response.data[1];
						var employerListHTML = '<option value="">Select...</option>';
						$.each(pdPlans, function(index, plan) {
							employerListHTML += '<option value="' + plan.plan_id + '">' + plan.employer_name + '</option>';
						});
						$('#pd_employeer_list').html(employerListHTML);
						if(Object.keys(pdPlans).length === 1) {
							$('#pd_employeer_list option').eq(1).prop('selected', true);
						}
					},
					function error() {
						printError('error in response.');
					},
					function done() {
						$('#payroll_activate_frm').removeClass('attach-loader');
					}
				);
			}
			catch(err) {
				printError(err.message);
			}
		});
		
	}());
	
	/* global $ */
	(function () {
	  var $sz_container = $('#size_container');
	  var pagination = $('.pagination');
	  // var $pageProducts = $('.product_container');
	
	  // function animateProduct(i, product, speed) {
	  //   var $product = product;
	  //   window.setTimeout(function() {
	  //     $product.addClass('animated-fade-in');
	  //   }, speed * i);
	  // }
	
	  function categorySwatchHover () {
	    var regex = /[A-Z]+\.JPG/;
	    var categoryPageSwatch = $('.product_container .alternates').find('.swatch');
	    function hover_in() {
	      var product_img;
	      var c_id;
	      var src;
	      var new_src;
	
	      c_id = $(this).attr('data-color-id');
	      product_img = $(this).parent().parent().find('.main-img');
	      if (c_id) {
	        src = product_img.attr('src');
	        new_src = src.replace(regex, c_id + '.JPG');
	        product_img.attr('src', new_src);
	      }
	    }
	
	    function hover_out() {
	      var product_img = $(this).parent().parent().find('.main-img');
	      product_img.attr('src', product_img.data('src'));
	    }
	
	    if (categoryPageSwatch.length) {
	      categoryPageSwatch.hover(hover_in, hover_out);
	    }
	  }
	
	  // $pageProducts.each(function(id) {
	  //   $(this).addClass('invisible');
	  //   animateProduct(id, $(this), 75);
	  // });
	
	    // ../modules/quicklook.js
	  quicklook.init();
	    // don't show empty size container (even if contains hidden items)
	  if ($sz_container.children().length < 2) {
	    $sz_container.hide();
	  }
	
	    /*
	        Initialize interactive filters and sorting.
	        => ../modules/filters_sort.js
	    */
	  filtersAndSort.init();
	
	    /*
	        Category page swatch hover:
	            Change category image color
	            when swatch is hovered
	    */
	  categorySwatchHover();
	
	    // Highlight active page in pagination bar
	  if (pagination) {
	    pagination.find('.page').each(function() {
	      var pageLabel = $(this);
	      if (pageLabel.parent('a').attr('href') === '') {
	        pageLabel.addClass('active');
	      }
	    });
	  }
	}());
	
	/* global $ */
	(function () {
	    // Swatch hover-in/out
	  $('#product').find('.swatch').swatch();
	    // Image zoom functionality
	  $('#image_zoom').elevateZoom({
	    easing: true,
	    borderSize: 1,
	    borderColour: '#eee',
	    lensColour: '#eee',
	    cursor: 'pointer',
	    zoomWindowHeight: parseInt($('#single_image').find('img').data('height'), 10),
	    zoomWindowWidth: parseInt($('#single_image').find('img').data('height'), 10)
	  });
	    // Product page thumbnails
	    // ../modules/product_page.js
	  $('#product_images').thumbnails({
	    stickyThumbs: true,
	    checkHeightInterval: 200,
	    responsive: true
	  });
	    // Size validation
	  $('#cartform').validateSzEmbroidery({
	    validate: true
	  });
	  $('.ql #cartform').validateSzEmbroidery({
	    validate: false
	  });
	    // Size and price
	  $('#product').sizeControls();
	    // Featured items carousel
	  $('#complete_outfit_cycle').slick({
	    dots: false,
	    speed: 400,
	    slidesToShow: 3,
	    slidesToScroll: 3,
	    prevArrow: $('.nav-arrow.prev'),
	    nextArrow: $('.nav-arrow.next'),
	    responsive: [{
	      breakpoint : 1100,
	      settings : {
	        slidesToShow: 2,
	        slidesToScroll: 2
	      }
	    }]
	  });
	}());
	/* global $ */
	(function spiEmbroidery() {
	  $('.js-cart-row').each(function() {
	    var row = $(this),
	      embroideryToggle = row.find('.embroidery-toggle'),
	      embroideryDetails = row.find('.embroidery-details');
	    embroideryToggle.on('click', function() {
	      var $self = $(this);
	      if ( $self.hasClass('expand') ) {
	        $self.removeClass('expand').addClass('collapse');
	      } else {
	        $self.removeClass('collapse').addClass('expand');
	      }
	      embroideryDetails.slideToggle('fast');
	    });
	  });
	}());
	
	(function() {
		'use strict';
		var isShoppingCart = function() {
			return $('#ajx_shopping_cart').length;
		}();
		var isSecureCheckout = function() {
			return $('#ajx_checkout_cart').length;
		}();
		/*
		  Checkout Page source code.
		*/
		var initSecureCheckout = function() {
			var cartElements = {
				checkoutWrapper: $('#one_step_checkout'),
				checkoutForm: $('#payment_form'),
				inputs: $('#one_step_checkout').find('.form-ctrl'),
				paymentSelector: $('#payment_methods').find('.pm-select'),
				newCustomerChk: $('#is_new_customer'),
				copyToShippingChk: $('#is_same_shipping'),
				processOrderBtn: $('.place-order-btn'),
				billingForm: $('#checkout_billing'),
				shippingForm: $('#checkout_shipping'),
				shipMethodsList: $('#shipping_methods'),
				shippingSelector: $('#shipping_methods').find('.rdo-ship'),
				shipState: $('.ajx-ship-state'),
				shipZipCode: $('.ajx-ship-zip'),
				pdEligibleChk: $('#eligible_for_pd'),
				pdApplyBtn: $('.ajx-apply-pd'),
				cc: $('#credit_card'), //cc payment wrapper
				ccInputs: $('#credit_card .form-ctrl'),
				ccExpMonth: $('#ccm'), //cc month expy
				ccExpYear: $('#ccy'), //cc year expy
				billZipCode: $('.ajx-trigger-blr'),
				billState: $('.ajx-trigger-chg'),
				quickRegisterPass: $('#quick_register'),
				paypalForm: $('#submit_to_paypal'),
				paypalBtn: $('.paypal-btn'),
				poKey: $('.ajx-po-key'),
				poApplyBtn: $('.ajx-apply-po'),
				promoToggle: $('#toggle_promo'),
				promoDiv: $('#promo'),
				promoField: $('.ajx-promocode'),
				promoCode: $('#promo_code'),
				gcField: $('.ajx-gc'),
				//applyPromoBtn: $('.ajx-get-promo'),
				//applyGCButton: $('.ajx-apply-gc'),
				cartSummary: $('#cart_summary'),
				instoreList: $('#store_list')
			};
			var cart = {
				/* === CONFIG === */
				config: {
					counter: 0,
					requiredFields: $('.validator--required'),
					filepath: '/orders_launch/one_step_service.php',
					errorMessage: 'We are sorry, but we cannot process your request. Please contact customer service if you keep seeing this error.'
				},
				/* === HELPER CART FUNCTIONS === */
				errorHandler: {
					throwError: function(errorMessage) {
						var alertBox = $('#checkout_error');
						if(alertBox.length) {
							alertBox.html(errorMessage + '<span class="msg-close">&times</span>');
							alertBox.message({
								removeOnClose: true
							});
							alertBox.scroll({
								speed: 200
							});
						} else {
							alertBox = $('<div></div>');
							alertBox.attr('id', 'checkout_error');
							alertBox.addClass('msg error-msg');
							alertBox.html(errorMessage + '<span class="msg-close">&times</span>');
							alertBox.prependTo('#one_step_checkout');
							alertBox.message({
								removeOnClose: true
							});
							alertBox.scroll({
								speed: 200
							});
						}
					},
					throwShippingError: function() {
						$('.js-shipping-error').removeClass('hidden').message();
						$('.js-shipping-error').scroll({speed: 200});
					},
					hideShippingError: function() {
						$('.js-shipping-error').addClass('hidden');
					}
				},
				utils: {
					promoErrorTimeout: null,
					lock: function(counter) {
						// lock/unlock cart processing based on ajax request completion
						if(counter > 0) {
							cartElements.processOrderBtn.addClass('disabled');
						} else if(counter === 0) {
							cartElements.processOrderBtn.removeClass('disabled');
						}
					},
					getGrandTotal: function() {
						var grandTotal = $('#ajx_grand_total').text();
						return parseFloat(grandTotal);
					},
					setCreditCardExpiry: function() {
						var currDate = new Date(),
							currMonth = currDate.getMonth() + 1,
							currYear = currDate.getFullYear();
						cartElements.ccExpMonth.val(currMonth);
						cartElements.ccExpYear.val(currYear);
					},
					clearPassword: function() {
						// Clear password field if 'is new customer' unchecked.
						if(!cartElements.newCustomerChk.is(':checked')) {
							cartElements.quickRegisterPass.val('');
						}
					},
					trimInput: function() {
						var input = $(this),
							trm;
						// credit card number -- remove all white space.
						if(input.attr('name') === 'credit_card_number' || input.attr('name') === 'phone') {
							if(input.attr('name') === 'phone') {
								trm = input.val().replace(/[\D]+/g, '');
							} else {
								trm = input.val().replace(/[^A-Za-z0-9]+/g, '');
							}
							trm = $.trim(trm);
						} else {
							trm = $.trim(input.val());
						}
						input.val(trm);
					},
					initShipInfo: function(billingFields) { // on page load copy over billing to shipping (if 'same as..' checked)
						billingFields.each(function() {
							var _self = $(this);
							cart.utils.populateShippingInfo.call(_self);
						});
					},
					populateShippingInfo: function() {
						var billField = $(this),
							shipField = $(this).data('assoc');
						$('#' + shipField).val(billField.val());
					},
					copyToShipping: function() {
						var sameShippingInfo = cartElements.copyToShippingChk.is(':checked'),
							billingFields = cartElements.billingForm.find('.form-ctrl'),
							tempVal = '';
						
						if(sameShippingInfo) {
							tempVal = cartElements.billZipCode.val();
							// If same as billing is checked, we populate billing info to shipping info
							cart.utils.initShipInfo(billingFields);
							cartElements.shippingForm.addClass('hidden');
							
							// Data will copied over to shipping in runtime while editing billing info
							billingFields.on('change blur', cart.utils.populateShippingInfo);
							cart.ajx.initShippingMethods(cartElements.billState.val());
							// Re-initialize shipping prices and exceptions
							//cart.ajx.initShippingMethods(cartElements.billState.val());
							cartElements.billState.on('change', function() {
								var userSelectedState = $(this).val();
								cart.ajx.initShippingMethods(userSelectedState);
							});
							cartElements.billZipCode.on('focus', function() {
								tempVal = $(this).val();
							});
							cartElements.billZipCode.on('blur', function() {
								if($(this).val() !== tempVal) {
									cart.ajx.initEvents();
								}
							});
						} else {
							tempVal = cartElements.shipZipCode.val();
							// Stop watching for changes in billing info
							billingFields.off('change blur');
							cartElements.shippingForm.removeClass('hidden');
							
							cart.ajx.initShippingMethods(cartElements.shipState.val());
							//cart.ajx.initShippingMethods(cartElements.shipState.val());
							// Ajax is triggered if shipping info is updated
							cartElements.shipZipCode.on('focus', function() {
								tempVal = $(this).val();
							});
							cartElements.shipZipCode.on('blur', function() {
								if($(this).val() !== tempVal) {
									cart.ajx.initEvents();
								}
							});
							cartElements.shipState.on('change.exception', function() {
								var userSelectedState = $(this).val();
								cart.ajx.initShippingMethods(userSelectedState);
							});
						}
					},
					updateCartBalance: function(ajaxResponse) {
						// On successful ajax response, update cart balance.
						var pricing = {};
						var payrollData = ajaxResponse.payroll_deduction_data;
						if(ajaxResponse.error) {
							printError('here has been an error calculating checkout pricing.');
							$('#ajx_product_total').addClass('hidden').text('');
							$('#ajx_embroidery_charges').addClass('hidden').text('');
							$('#ajx_discount').addClass('hidden').text('');
							$('#ajx_shipping').addClass('hidden').text('');
							$('#ajx_tax').addClass('hidden').text('');
							$('#ajx_grand_total').addClass('hidden').text('');
						} else {
							pricing.productTotal = (Number(ajaxResponse.product_total)).toFixed(2, 10);
							pricing.embroideryCharges = (Number(ajaxResponse.embroidery_charges)).toFixed(2, 10);
							pricing.shipping = (Number(ajaxResponse.shipping)).toFixed(2, 10);
							pricing.tax = (Number(ajaxResponse.tax)).toFixed(2, 10);
							pricing.discount = (Number(ajaxResponse.discount)).toFixed(2, 10);
							pricing.balanceDue = (Number(ajaxResponse.balance_due)).toFixed(2, 10);
							
							$('#ajx_product_total').removeClass('hidden').text(pricing.productTotal);
							$('#ajx_embroidery_charges').removeClass('hidden').text(pricing.embroideryCharges);
							$('#ajx_shipping').removeClass('hidden').text(pricing.shipping);
							$('#ajx_tax').removeClass('hidden').text(pricing.tax);
							$('.ajx-promo-discount').text(pricing.discount);
							// grand total
							$('#ajx_grand_total').removeClass('hidden').text(pricing.balanceDue);
							// update payroll balance:
							
							try {
								if(payrollData && payrollData.constructor !== Array) {
									cart.utils.updatePayrollBalance(ajaxResponse);
								} else if(payrollData.constructor === Array && payrollData.length) {
									cart.utils.updatePayrollBalance(ajaxResponse);
								}
							}
							catch(e) {
								printError('Unable to process payroll deduction info', e);
							}
							
							// 1. check if checkout balance IS ZERO:
							if(ajaxResponse.the_bill_is_paid) { // 1.2 if balance is zero:
								// Disable credit card payment
								cart.utils.creditCardOff();
							} else {  // 1.3 if balance is NOT zero:
								// Enable credit card payment
								cart.utils.creditCardOn();
							}
						}
					},
					updateCartPayments: function(ajaxResponse) {
						var payments = ajaxResponse.payments.data;
						// Check if payments wrapper exists. If no, create one
						if(!$('#payments').length) {
							$('<div></div>')
								.addClass('uppercase payments')
								.attr('id', 'payments')
								.insertAfter('.cart-tax');
						}
						// check if there is any payment data from ajax response
						if(payments.length) {
							var p,
								pHTML = '';
							// Iterate through each payment and render in HTML
							for(var i = 0, x = payments.length; i < x; i += 1) {
								p = payments[i];
								p.amount = Number(p.amount).toFixed(2, 10);
								//  -- add method id to payment row
								pHTML += '<div class="payment-item row" data-payment-id="' + p.id + '" data-method-id="' + p.method_id + '">';
								pHTML += '<span class="ajx-payment-info pull-left"><strong>' + p.descr;
								if(!$.isEmptyObject(p.payment_name)) {
									pHTML += ' (' + p.payment_name + ')';
								}
								pHTML += ' <button class="ajx-del-payment btn btn--sm btn--gamma" type="button" title="Cancel this payment">&times;</button>';
								pHTML += ' </strong></span>';
								pHTML += '<span class="ajx-payment-amount currency discount pull-right">' + p.amount + '</span>';
								pHTML += '</div>';
							}
							$('#payments').html(pHTML);
						}
						
						cart.utils.updateCartBalance(ajaxResponse);
					},
					updateShippingPrices: function(ajaxResponse) {
						var selectedShipping = $('.rdo-ship:checked'),
							shippingCode = selectedShipping.val(),
							x = ajaxResponse.shipping;
						if(x !== undefined) {
							selectedShipping.closest('.shipping-method-item').attr('data-ship-price', x[shippingCode]);
						} else {
							selectedShipping.closest('.shipping-method-item').attr('data-ship-price', '');
						}
					},
					creditCardOn: function() {
						// cartElements.cc.removeClass('hidden')
						//     .prev('.pm-select').find('.chk-pm').attr('checked', true);
						$('#cc_form').removeClass('hidden');
						$('#paid_in_full').addClass('hidden');
					},
					creditCardOff: function() {
						// cartElements.cc.addClass('hidden')
						//     .prev('.pm-select').find('.chk-pm').attr('checked', false);
						$('#cc_form').addClass('hidden');
						$('#paid_in_full').removeClass('hidden');
					},
					creditCardValidate: function(x) {
						if(x) {
							cartElements.ccInputs.removeClass('novalidate');
						} else {
							cartElements.ccInputs.addClass('novalidate');
						}
					},
					updateGCMessage: function(error, message) {
						if(error) {
							if(error.class === 'warning') {
								toastr.warning(message);
							}
							else if(error.class === 'error') {
								toastr.error(message);
							}
						} else {
							toastr.success(message);
						}
					},
					updatePOMessage: function(error, message) {
						if(error) {
							if(error.class === 'warning') {
								toastr.warning(message);
							}
							else if(error.class === 'error') {
								toastr.error(message);
							}
						} else {
							toastr.success(message);
						}
					},
					GCPOPassword: {
						showFor: function(pt) {
							switch(pt) {
								case 'gc':
									$('#gc_password_area').removeClass('hidden');
									break;
								case 'po':
									$('#po_password_area').removeClass('hidden');
									break;
								default:
									printError('[GC_PO_PASSWORD]: Unknown payment type');
									break;
							}
							;
						},
						hideFor: function(pt) {
							switch(pt) {
								case 'gc':
									$('#gc_password_area').addClass('hidden');
									$('#gc_password').val('');
									break;
								case 'po':
									$('#po_password_area').addClass('hidden');
									$('#po_password').val('');
									break;
								default:
									printError('[GC_PO_PASSWORD]: Unknown payment type');
									break;
							}
							;
						},
					},
					confirmPayrollElibility: function() { // make sure employee confirms her eligibility before applying
						if(cartElements.pdEligibleChk.is(':checked')) {
							$('.ajx-apply-pd').removeClass('disabled');
						} else {
							$('.ajx-apply-pd').addClass('disabled');
						}
					},
					showPayrollMessage: function(x) {
						if(x.warning) {
							toastr.warning('Remaining balance has been applied to your order. Please use another payment method for the remaining balance.');
						} else {
							toastr.success('Payroll deduction payment has been applied.');
						}
					},
					updatePayrollBalance: function(ajaxResponse) {
						var remainingBalance = parseFloat(ajaxResponse.payroll_deduction_data.balance_remaining).toFixed(2);
						if(typeof remainingBalance !== 'undefined') {
							$('#pd_balance').text(remainingBalance);
							if(ajaxResponse.the_bill_is_paid) {
								$('#pd_show_pay_buttons').addClass('hidden');
								cart.utils.showPayrollMessage({warning: false});
							} else if(remainingBalance == 0.00 && !ajaxResponse.the_bill_is_paid) {
								$('#pd_show_pay_buttons').addClass('hidden');
								cart.utils.showPayrollMessage({warning: true});
							} else {
								$('#pd_show_pay_buttons').removeClass('hidden');
							}
						}
					},
					getAllShippingMethods: function() {
						var smArray = [];
						cartElements.shipMethodsList
							.find('.rdo-ship')
							.each(function() {
								smArray.push($(this).val());
							});
						return smArray.join(',');
					},
					togglePaymentMethods: function() {
						var pm = $(this);
						var chk = pm.find('.chk-pm');
						var isChecked = chk.is(':checked');
						var collapsePayment = chk.data('collapse-payment');
						console.log(collapsePayment);
						var inputIds = {
							purchase_orders: "po_key",
							gift_certificate: "gift_certificate_code"
						};
						var corrispondingInputField = $("#" + inputIds[collapsePayment]);
						if(isChecked) {
							// Credit card is a default payment method
							// and should never be hidden
							if(collapsePayment !== 'credit_card') {
								chk.prop('checked', false);
								$('#' + collapsePayment).addClass('hidden');
								cart.utils.manageRequiredCCFields($("#" + collapsePayment), false)
							}
						} else {
							chk.prop('checked', true);
							$('#' + collapsePayment).removeClass('hidden');
							cart.utils.manageRequiredCCFields($("#" + collapsePayment), true)
						}
						console.log(corrispondingInputField); // CONSOLE LOG HERE!!!!!!!!!!!!!!!!
					},
					manageRequiredCCFields: function(elementField, addTrue_RemoveFalse) {
						if(addTrue_RemoveFalse) {
							cart.config.requiredFields.push(elementField);
						} else {
							var index = cart.config.requiredFields.indexOf(elementField);
							cart.config.requiredFields.splice(index, 1);
						}
					},
					populatePaypalInfo: function(response) {
						var paypalData = response.paypal_info;
						$('#pp_first_name').val(paypalData.first_name);
						$('#pp_last_name').val(paypalData.last_name);
						$('#pp_address1').val(paypalData.address1);
						$('#pp_address2').val(paypalData.address2);
						$('#pp_state').val(paypalData.state);
						$('#pp_zip').val(paypalData.zip);
						$('#pp_city').val(paypalData.city);
						$('#pp_email').val(paypalData.email); // paypal customer email
						$('#pp_phone').val(paypalData.phone); // paypal phone number
						$('#pp_business').val(paypalData.paypal_email); // paypal business email
						$('#pp_amount_1').val(paypalData.amount_1); // cart amount
						$('#pp_cpp_header_image').val(paypalData.cpp_header_image);
						$('#pp_custom').val(paypalData.custom);
						$('#pp_invoice').val(paypalData.invoice); // invoice #
						$('#pp_item_name_1').val(paypalData.item_name_1);
						$('#pp_shopping_url').val(paypalData.shopping_url);
						$('#pp_use_paypal').val(paypalData.use_paypal);
						$('#pp_cancel_return').val(response.paypal_info.cancel_return);
						if(cartElements.paypalForm.length) {
							if(paypalData.pp_test_action) {
								cartElements.paypalForm.attr('action', paypalData.pp_test_action);
							}
							cartElements.paypalForm.submit();
						}
					},
					submitPaymentsState: function(state) {
						cartElements.processOrderBtn.prop('disabled', !state);
						cartElements.paypalBtn.prop('disabled', !state);
						if(state) {
							cartElements.processOrderBtn.removeClass('disabled');
							cartElements.paypalBtn.removeClass('disabled');
							console.log("Checkout is now ready!");
						} else {
							cartElements.processOrderBtn.addClass('disabled');
							cartElements.paypalBtn.addClass('disabled');
							console.log("Information is still required/pending...");
						}
					}, // GG
					checkAllRequiredInputs: function() {
						console.log('Checking required inputs...'); // CONSOLE LOG HERE!!!!!!!!!!!!!!!!
						cart.config.checkoutReady = 0;
						console.log(cart.config.requiredFields); // CONSOLE LOG HERE!!!!!!!!!!!!!!!!
						$.each(cart.config.requiredFields, function(i, v) {
							var value = $(v).val();
							if(value === '') {
								cart.config.checkoutReady++;
							}
						});
						console.log('Done.'); // CONSOLE LOG HERE!!!!!!!!!!!!!!!!
						cart.utils.submitPaymentsState(cart.config.checkoutReady === 0);
					},
					updateShippingMethodToInstore: function() {
						var shipMethod = $(this),
							shipMethodType = shipMethod.val();
						
						if(shipMethodType.toString() === 'INSTORE') {
							$('#store_list').removeClass('hidden');
							$('#store_list').find('input[type="hidden"]').attr('disabled', false);
							$('#customer_shipping_info').addClass('hidden');
							$('#shipment_form, sp_shipment_form').find('.form-ctrl').attr('disabled', true);
							// added by HY 12/20/2016=====
							var instore_zip = $('#instore_zip_code').val();
							var instore_state = $('#instore_ship_state').val();
							cart.ajx.requestInstoreInfo(instore_zip, instore_state);
							
						} else {
							$('#store_list').addClass('hidden');
							$('#store_list').find('input[type="hidden"]').attr('disabled', true);
							$('#customer_shipping_info').removeClass('hidden');
							$('#shipment_form, sp_shipment_form').find('.form-ctrl').attr('disabled', false);
						}
					},
					initInstorePickupOnSelected: function() {
						cartElements.instoreList.find('li').each(function() {
							var instoreListItem = $(this);
							
							function prepopulateShippingInfo() {
								if(instoreListItem.hasClass('selected')) {
									$('#instore_ship_name').val(instoreListItem.data('shipName'));
									$('#instore_ship_address_1').val(instoreListItem.data('shipAddress'));
									$('#instore_ship_city').val(instoreListItem.data('shipCity'));
									$('#instore_ship_state').val(instoreListItem.data('shipState'));
									$('#instore_zip_code').val(instoreListItem.data('shipZip'));
								}
							}
							
							prepopulateShippingInfo();
							
							instoreListItem.on('click', function() {
								$(this)
									.siblings().removeClass('selected')
									.end()
									.addClass('selected');
								
								prepopulateShippingInfo();
								// added by HY 12/20/2016=====
								var instore_zip = $('#instore_zip_code').val();
								var instore_state = $('#instore_ship_state').val();
								cart.ajx.requestInstoreInfo(instore_zip, instore_state);
								
							});
						});
					}
				}, // end of utils
				
				/* === AJAX CALLS ===*/
				ajx: {
					initShippingMethods: function(currSelShipMethod) {
						// initialize shipping methods on page load. This function will only be called once.
						// Function takes one optional parameter (shipping state)
						var shipMethodsList = cartElements.shipMethodsList,
							counter = cart.config.counter,
							shipStateSelected = currSelShipMethod || cartElements.shipState.val(),
							shipMethod, shipMethodSelect, shipCode, shipName, shipPrice, exceptionObj;
						
						counter += 1;
						
						$.ajax({
							url: cart.config.filepath,
							data: {
								what_to_do: 'shipping_method_cost',
								shipping_method: cart.utils.getAllShippingMethods,
								ship_state: cartElements.shipState.val(),
								zip_code: cartElements.shipZipCode.val()
							},
							success: function(response) {
								if(typeof response === 'object') {
									shipMethodsList.find('.shipping-method-item').each(function() {
										shipMethod = $(this);
										shipMethodSelect = shipMethod.find('.rdo-ship');
										shipCode = shipMethodSelect.val();
										shipName = shipMethod.attr('data-ship-name');
										shipPrice = response.shipping[shipCode];
										// 1. Initialize prices for ALL shipping methods
										if(shipPrice !== null || typeof shipPrice !== 'undefined') {
											if(shipPrice > 0) {
												shipMethod.attr('data-ship-price', '$' + shipPrice);
											} else {
												shipMethod.attr('data-ship-price', 'FREE');
											}
										} else {
											shipMethod.attr('data-ship-price', '');
										}
									});
									
									try {
										shipMethodsList.find('.shipping-method-item').each(function() {
											var method = $(this),
												exceptionstates = [],
												shipName = $(this).data('shipName'),
												shipCode = $(this).data('shipCode');
											if(response.shipping_exceptions) {
												exceptionObj = response.shipping_exceptions;
												if(exceptionObj[shipName]) {
													if(exceptionObj[shipName][shipCode]) {
														method.addClass('exception');
														for(var i = 0; i < exceptionObj[shipName][shipCode].length; i++) {
															if(exceptionObj[shipName][shipCode][i] !== null || typeof exceptionObj[shipName][shipCode][i] !== 'undefined') {
																exceptionstates.push(exceptionObj[shipName][shipCode][i]);
															}
														}
														method.attr('data-exceptions', exceptionstates.join(','));
													} else {
														method.addClass('no-exception');
													}
												} else {
													// Exception doesn't have separate shipping codes.
												}
											} else {
												// No exceptions found
											}
										});
									}
									catch(e) {
										printError(e);
									}
									
									$('.exception').addClass('hidden');
									//$('.no-exception').find('.rdo-ship').prop('checked', true);
									if(response.shipping_exceptions) {
										if(shipStateSelected) {
											$('.exception').each(function() {
												var self = $(this);
												var exceptionStates = self.data('exceptions').split(',');
												for(var i = 0; i < exceptionStates.length; i++) {
													if(shipStateSelected === exceptionStates[i]) {
														self.removeClass('hidden');
														$('.no-exception').addClass('hidden');
														break;
													} else {
														$('.no-exception').removeClass('hidden');
													}
												}
											});
										} else {
											$('.no-exception').removeClass('hidden');
										}
									}
									
									if($('.rdo-ship:checked').parent('.shipping-method-item.hidden').length) {
										$('.shipping-method-item:not(.hidden):first').find('.rdo-ship').prop('checked', true);
									}
									cart.ajx.requestCartBalance($('.rdo-ship:checked').val());
									
								} else {
									printWarning('Response is NOT in json format.');
								}
								//cart.ajx.initEvents();
								counter -= 1;
								
								var shippingMethodList = $('.shipping-method-list');
								shippingMethodList
									.find('.shipping-method-item')
									.sortShippingMethodByPrice(shippingMethodList);
								
								cartElements.shippingSelector.on('change', cart.ajx.initEvents);
								cartElements.shippingSelector.on('change.instore', cart.utils.updateShippingMethodToInstore);
								cart.utils.initInstorePickupOnSelected();
								
								shippingMethodList.fadeIn('fast');
								
							},
							error: function() {
								printError('Error fetching shipping information.');
							}
						});
						
						/*jQ fn added by GG 11/22/2017*/
						jQuery.fn.sortShippingMethodByPrice = function(replaceContents) {
							var sorted = $(this).sort(function(a,b) {
								var sortObject = {
									a: parseFloat($(a).data('shipPrice').replace(/FREE/gi,'$0.00').replace('$','')),
									b: parseFloat($(b).data('shipPrice').replace(/FREE/gi,'$0.00').replace('$','')),
								};
								// console.log(sortObject);
								sortObject.result = sortObject.a > sortObject.b;
								return sortObject.result;
							});
							if(cartElements.instoreList) {
								$(sorted).each(function(i, v) {
									if($(v).data('shipCode') === 'INSTORE') {
										$(v).append(cartElements.instoreList);
									}
								});
							}
							$(replaceContents).html(sorted);
							// Keep chain-able
							return this;
						};
						
					}, // end of initShippingMethods
					requestCartBalance: function(shipMethod) {
						/*
						Update shipping prices is run on initial page load,
						and any updates to shipping methods, state, and/or zip code.
						*/
						var counter = cart.config.counter;
						counter += 1;
						$.ajax({
							url: cart.config.filepath,
							beforeSend: function() {
								$('#price_breakdown').addClass('attach-loader');
							},
							data: {
								what_to_do: 'price_details',
								shipping_method: shipMethod,
								ship_state: cartElements.shipState.val(),
								zip_code: cartElements.shipZipCode.val()
							},
							success: function(response) {
								cart.utils.updateCartBalance(response);
								cart.utils.updateShippingPrices(response);
								cart.utils.updateCartPayments(response);
								counter -= 1;
							},
							complete: function() {
								$('#price_breakdown').removeClass('attach-loader');
							},
							error: function() {
								printError('Error updating cart information.');
							}
						});
					}, // end of updatePrices
					
					/* === PROMO CODE === */
					applyPromo: function(code) {
						if(cart.utils.getGrandTotal() > 0) {
							var counter = cart.config.counter;
							counter += 1;
							$.ajax({
								url: cart.config.filepath,
								data: {
									what_to_do: 'edit_promo_info',
									shipping_method: cartElements.shipMethodsList.find('.rdo-ship:checked').val(),
									ship_state: cartElements.shipState.val(),
									zip_code: cartElements.shipZipCode.val(),
									promo_code: code
								},
								success: function(response) {
									var promoNameHTML = '',
										promoRemoveHTML = '',
										promoMsg = '';
									if(response.promo.no_match) {
										// promo code is invalid
										//printError('Invalid promo code.');
										toastr.error('Invalid or expired promo code.');
										$('#promo_error').removeClass('hidden');
										$('.js-promo').addClass('hidden');
										$('#promo_msg').remove();
										cart.utils.promoErrorTimeout = setTimeout(function() {
											$('#promo_error').addClass('hidden');
										}, 2500);
									} else if ( !response.promo_applied ) {
										toastr.error('Promo code does not apply.');
										var temp = $('#promo_error').text();
										$('#promo_error').text( 'Promo code does not apply.' ).removeClass('hidden');
										$('.js-promo').addClass('hidden');
										$('#promo_msg').remove();
										cart.utils.promoErrorTimeout = setTimeout(function() {
											$('#promo_error').addClass('hidden');
											$('#promo_error').text( temp );
										}, 2500);
									} else {
										$('#promo_error').addClass('hidden');
										// Add promo to cart total (with delete button)
										$('.js-promo').removeClass('hidden');
										
										if ( response.promo_applied && response.promo )
										{
											var promoNameHTML_array  = [];
											var promoNameHTML_string = '';
											var promoCodes_array     = [];
											var promoCodes_string    = '';
											for ( var i in response.promo )
											{
												if ( response.promo_applied.indexOf( response.promo[ i ].promo_code ) !== -1 )
												{
													promoNameHTML_array.push( response.promo[ i ].promo_name );
													promoCodes_array.push( response.promo[ i ].promo_code );
												}
											}
											promoNameHTML_string = promoNameHTML_array.join( ', ' );
											promoCodes_string    = promoCodes_array.join( ', ' );
	
											promoNameHTML += 'Discount(' + promoNameHTML_string + ')';
											promoRemoveHTML += ' <button class="ajx-del-promo btn btn--sm btn--gamma" type="button">&times;</button>';
											$( '.ajx-promo-name' ).attr( 'data-promo-code', response.promo[ 0 ].promo_code ).html( promoNameHTML + promoRemoveHTML );
	
											// Show promo message if promo applied successfully.
											promoMsg += 'Promotional code' + (promoCodes_array.length > 1 ? 's' : '');
											promoMsg += ' <strong>' + promoCodes_string + '</strong> ' + (promoCodes_array.length > 1 ? 'were' : 'was') + ' applied';
	
											for ( var i in response.promo )
											{
												if ( response.promo_applied.indexOf( response.promo[ i ].promo_code ) !== -1 )
												{
													promoMsg += "<br>";
													promoMsg += '<strong>' + response.promo[ i ].promo_name + '</strong> &dash; ' + response.promo[ i ].promo_description;
												}
											}
	
	
											if ( !$( '#promo_msg' ).length )
											{
												$( '<div></div>' ).attr( 'id', 'promo_msg' ).addClass( 'msg success-msg' ).html( promoMsg ).appendTo( '#promo' );
											}
											else
											{
												$( '#promo_msg' ).html( promoMsg );
											}
	
											toastr.success( 'Promo code applied.' );
	
										}
									}
									cart.utils.updateCartBalance(response);
									$('.rdo-ship:checked').closest('.shipping-method-item').attr('data-ship-price', ( response.shipping === '0.00' ? 'FREE' : '$'+response.shipping ) );
									counter -= 1;
								},
								error: function() {
									cart.errorHandler.throwError(cart.config.errorMessage);
									printError('Promo code server error or timeout.');
								}
							});
						} else {
							toastr.warning('Your balance is already covered.', 'Unable to apply promo');
						}
					}, // end of applyPromo
					deletePromo: function() {
						var counter = cart.config.counter;
						counter += 1;
						$.ajax({
							url: cart.config.filepath,
							data: {
								what_to_do: 'delete_promo_info',
								shipping_method: $('.rdo-ship:checked').val(),
								ship_state: cartElements.shipState.val(),
								zip_code: cartElements.shipZipCode.val(),
								promo_code: $('.ajx-promo-name').attr('data-promo-code')
							},
							success: function(response) {
								// Hide promo row
								$('.js-promo').toggleClass('hidden');
								// Delete all promo info including promo messages.
								$('#promo_msg').remove();
								$('.ajx-promocode').val('');
								$('.ajx-promo-name').attr({
									'data-promo-code': ''
								}).addClass('pull-left').html('No promotions were applied to this order.');
								$('.ajx-promo-discount').text('0.00');
								cart.utils.updateCartPayments(response);
								cart.utils.updateCartBalance(response);
								$('.rdo-ship:checked').closest('.shipping-method-item').attr('data-ship-price', ( response.shipping === '0.00' ? 'FREE' : '$'+response.shipping ) );
								counter -= 1;
								
								toastr.success('Promo code was removed.');
							},
							error: function() {
								cart.errorHandler.throwError(cart.config.errorMessage);
								printError('Promo code server error or timeout.')
							}
						});
					}, // end of deletePromo
					
					/* === PAYMENTS === */
					applyPayment: function(paymentType, passwordProtected) {
						var counter = cart.config.counter,
							requestType = 'GET',
							dataToSend = {},
							callbackFunc,
							status, passwordForm;
						counter += 1;
						
						if(cart.utils.getGrandTotal() > 0) {
							if(paymentType === 'gc') {
								dataToSend = {
									what_to_do: 'validate_gift_certificate_payment',
									shipping_method: $('.rdo-ship:checked').val(),
									ship_state: cartElements.shipState.val(),
									zip_code: cartElements.shipZipCode.val(),
									gc_key: $('.ajx-gc').val()
								};
								if(passwordProtected) {
									dataToSend.gc_password = $('#gc_password').val();
								}
								callbackFunc = function(response) {
									var gc = response.gc_po,
										paymentApplied = gc.flags.gc_po_payment_applied,
										requiresPassword = gc.flags.gc_po_passw_required,
										message = gc.mesage;
									if(gc.status === true) {
										// GC payment applied successfully
										cart.utils.updateCartPayments(response);
										cart.utils.updateCartBalance(response);
										cart.utils.GCPOPassword.hideFor('gc');
										cart.utils.updateGCMessage(null, 'Gift certificate payment has been applied.');
									} else {
										// GC payment didn't apply or applied with warnings
										if(paymentApplied === true) {
											// GC payment applied partially (show error message and apply as payment)
											cart.utils.updateGCMessage({class: 'warning'}, message);
											cart.utils.updateCartPayments(response);
											cart.utils.updateCartBalance(response);
										} else {
											// PO payment did NOT apply. Show error message.
											cart.utils.updateGCMessage({class: 'error'}, message);
										}
										// show hide password form
										if(requiresPassword === true) {
											cart.utils.GCPOPassword.showFor('gc');
										} else {
											cart.utils.GCPOPassword.hideFor('gc');
										}
									}
								};
								requestType = 'GET';
							} else if(paymentType === 'pd') {
								dataToSend = {
									what_to_do: 'validate_pd_payment',
									plan_id: $('#plan_id').val(),
									emplid: $('#employee_id').val(),
									billing_type: $('#billing_type').val(),
									shipping_method: $('.rdo-ship:checked').val(),
									ship_state: cartElements.shipState.val(),
									zip_code: cartElements.shipZipCode.val()
								};
								callbackFunc = function(response) {
									// Display updated payroll balance
									cart.utils.updatePayrollBalance(response);
									cart.utils.updateCartPayments(response);
								};
								requestType = 'GET';
							} else if(paymentType === 'po') {
								dataToSend = {
									what_to_do: 'validate_purchase_order_payment',
									shipping_method: $('.rdo-ship:checked').val(),
									ship_state: cartElements.shipState.val(),
									zip_code: cartElements.shipZipCode.val(),
									po_key: cartElements.poKey.val() // purchase order key value.
								};
								if(passwordProtected) {
									dataToSend.po_password = $('#po_password').val();
								}
								callbackFunc = function(response) {
									var po = response.gc_po,
										paymentApplied = po.flags.gc_po_payment_applied,
										requiresPassword = po.flags.gc_po_passw_required,
										message = po.mesage;
									if(po.status === true) {
										// PO payment applied successfully
										cart.utils.updateCartPayments(response);
										cart.utils.updateCartBalance(response);
										cart.utils.GCPOPassword.hideFor('po');
										cart.utils.updatePOMessage(null, 'Purchase order payment has been applied.');
									} else {
										// PO payment didn't apply or applied with warnings
										if(paymentApplied === true) {
											// PO payment applied partially (show error message and apply as payment)
											cart.utils.updatePOMessage({class: 'warning'}, message);
											cart.utils.updateCartPayments(response);
											cart.utils.updateCartBalance(response);
										} else {
											// PO payment did NOT apply. Show error message.
											cart.utils.updatePOMessage({class: 'error'}, message);
										}
										// show hide password form
										if(requiresPassword === true) {
											cart.utils.GCPOPassword.showFor('po');
										} else {
											cart.utils.GCPOPassword.hideFor('po');
										}
									}
								};
								requestType = 'GET';
							} else {
								printError('Unknown payment type');
								return;
							}
							$.ajax({
								url: cart.config.filepath,
								type: requestType,
								data: dataToSend,
								success: function(response) {
									callbackFunc(response);
									counter -= 1;
								},
								error: function(jqXHR, textStatus, errorThrown) {
									cart.errorHandler.throwError(cart.config.errorMessage);
									printError('Error applying ' + paymentType);
									printError(textStatus);
									printError(errorThrown);
								}
							});
						} else {
							toastr.warning('Please remove existing payment before applying new one.', 'Balance is already covered.');
						}
					}, // end of applyPayment
					deletePayment: function() {
						var counter = cart.config.counter;
						var paymentId = $(this).parents('.payment-item').attr('data-payment-id');
						var methodId = $(this).parents('.payment-item').attr('data-method-id');
						counter += 1;
						$(this).parents('.payment-item').remove();
						$.ajax({
							url: cart.config.filepath,
							type: 'GET',
							data: {
								cancel_payment: paymentId,
								//  Send methodID on payment cancellation
								method_id: methodId,
								shipping_method: $('.rdo-ship:checked').val(),
								ship_state: cartElements.shipState.val(),
								zip_code: cartElements.shipZipCode.val()
							},
							success: function(response) {
								// toggle gift card message ONLY IF gc payment is deleted.
								methodId = parseInt(methodId);
								if(methodId === 2) {
									//$('#gc_error').remove();
									//$('#gc_success').remove();
									cart.utils.GCPOPassword.hideFor('gc');
									toastr.success('Gift certificate was removed as payment.');
									//cart.utils.updateGCMessage(response);
								}
								// display updated payroll balance (if deleting PD payment) once removed
								if(methodId === 3) {
									cart.utils.updatePayrollBalance(response);
									toastr.success('Payroll deduction was removed as payment.');
								}
								// PO - remove user messages
								if(methodId === 5) {
									// $('#po_error').remove();
									// $('#po_success').remove();
									cart.utils.GCPOPassword.hideFor('po');
									toastr.success('Purchase order was removed as payment.');
								}
								counter -= 1;
								cart.utils.updateCartPayments(response);
								cart.utils.updateCartBalance(response);
							},
							error: function() {
								cart.errorHandler.throwError(cart.config.errorMessage);
								printError('Couldnt delete payment.');
							}
						});
					}, // end of deletePayment
					validatePaypalPayment: function(d) {
						function showLoadingScreen() {
							var loadScreen = $('<div></div>'),
								loadMessage = $('<div></div>');
							loadScreen.addClass('pp-loader');
							loadMessage.addClass('pp-loader-message');
							loadMessage.text('Loading Paypal...');
							loadScreen.append(loadMessage);
							$('body').append(loadScreen);
						}
						
						function removeLoadingScreen() {
							if($('.pp-loader').length) {
								$('.pp-loader').remove();
							}
						}
						
						var counter = cart.config.counter,
							paypalData = {};
						cart.utils.creditCardValidate(false);
						// 2. Check if form fields are validated (frontend)
						try {
							if(cartElements.checkoutForm.valid()) {
								counter += 1;
								// 2.1 Prepare customer data to be sent to the server
								paypalData = {
									what_to_do: 'validate_paypal_payment',
									first_name: $('#first_name').val(),
									last_name: $('#last_name').val(),
									billing_name: $('#billing_name').val(),
									billing_address_1: $('#billing_address_1').val(),
									billing_address_2: $('#billing_address_2').val(),
									billing_city: $('#billing_city').val(),
									billing_state: $('#billing_state').val(),
									billing_zip_code: $('#billing_zip').val(),
									phone: $('#phone').val(),
									ship_name: $('#ship_name').val(),
									address_1: $('#ship_address_1').val(),
									address_2: $('#ship_address_2').val(),
									city: $('#ship_city').val(),
									state: $('#ship_state').val(),
									zip_code: $('#ship_zip').val(),
									ship_method: $('.rdo-ship:checked').val(),
									email: $('#email').val()
								};
								
								// Overwrite paypal shipping data with instore shipping data if instore is a selected shipping method.
								if(d.storePickup) {
									paypalData.ship_name = $('#instore_ship_name').val();
									paypalData.address_1 = $('#instore_ship_address_1').val();
									paypalData.address_2 = $('#instore_ship_address_2').val();
									paypalData.city = $('#instore_ship_city').val();
									paypalData.state = $('#instore_ship_state').val();
									paypalData.zip_code = $('#instore_zip_code').val();
								}
								
								// 2.2 Send prepared customer data to server
								$.ajax({
									url: cart.config.filepath,
									type: 'GET',
									data: paypalData,
									beforeSend: showLoadingScreen,
									success: function(response) {
										// If response format is other than JSON (could be a server error)
										if(typeof response === 'object') {
											// If server sends validation error
											if(response.error) {
												// Feedback to user.
												cart.errorHandler.throwError('[Validation error] ' + response.error);
												removeLoadingScreen();
											} else {
												// Submit data to paypal.
												cart.utils.populatePaypalInfo(response);
											}
										} else {
											cart.errorHandler.throwError(cart.config.errorMessage);
											removeLoadingScreen();
										}
										counter -= 1;
									},
									error: function() {
										cart.errorHandler.throwError(cart.config.errorMessage);
										removeLoadingScreen();
									}
								});
							} else {
								removeLoadingScreen();
							}
							cart.utils.creditCardValidate(true);
						}
						catch(err) {
							printError(err);
						}
					},
					initEvents: function() {
						var selectedShipMethod = $('.rdo-ship:checked').val();
						cart.ajx.requestCartBalance(selectedShipMethod);
					}, // end of initEventsaaaa
					
					requestInstoreInfo: function(instore_zipcode, instore_state) {
						
						var counter = cart.config.counter;
						counter += 1;
						$.ajax({
							url: cart.config.filepath,
							data: {
								what_to_do: "price_details",
								shipping_method: "INSTORE",
								zip_code: instore_zipcode,
								ship_state: instore_state
							},
							
							success: function(response) {
								cart.utils.updateCartBalance(response);
								counter -= 1;
							},
							
							error: function() {
								printError('Error requestInstore function.');
							}
						});
						
					}
				}, // end of ajx
				
				/* === CART INITIALIZATION === */
				init: function() {
					
					function initNotificationOptions() {
						toastr.options.closeButton = true;
						toastr.options.showDuration = 200;
						toastr.options.closeDuration = 100;
						toastr.options.preventDuplicates = true;
						toastr.options.positionClass = 'toast-top-right';
						// toastr.options.showMethod = 'slideDown';
						// toastr.options.hideMethod = 'slideUp';
						// toastr.options.closeMethod = 'slideUp';
					}
					
					initNotificationOptions();
					
					var shippingMethodSelected = function() {
						return $('.shipping-method-item:not(.hidden)').find('.rdo-ship:checked').length;
					};
					/* === Initial Load === */
					
					// Submit Payment buttons disabled on checkout load to be enabled after complete validation.
					// cart.utils.submitPaymentsState(false);
					// cart.utils.lock(cart.config.counter);
					// cartElements.ccInputs.on('blur change', cart.utils.checkAllRequiredInputs);
					//
					
					//cart.ajx.initShippingMethods(cartElements.billState.val());s
					cart.utils.copyToShipping();
					cart.utils.confirmPayrollElibility();
					cart.utils.setCreditCardExpiry();
					/* === Event triggers === */
					cartElements.inputs.on('blur', cart.utils.trimInput);
					cartElements.newCustomerChk.on('change', cart.utils.clearPassword);
					cartElements.copyToShippingChk.on('change', cart.utils.copyToShipping);
					cartElements.paymentSelector.on('click focus', cart.utils.togglePaymentMethods);
					/* === Ajax event triggers === */
					
					cartElements.shippingSelector.on('change', cart.ajx.initEvents);
					// When selecting shipping methods, check if shipping method is 'INSTORE'
					cartElements.shippingSelector.on('change.instore', cart.utils.updateShippingMethodToInstore);
					
					cartElements.shippingSelector.on('change.validate', function() {
						if(shippingMethodSelected()) {
							cart.errorHandler.hideShippingError();
						} else {
							cart.errorHandler.throwShippingError();
						}
					});
					/*
						 === Promo code ===
					*/
					
					// toggle promo
					if(cartElements.promoToggle.length) {
						cartElements.promoToggle.on('change', function() {
							var promoDiv = cartElements.promoDiv,
								promoError = $('#promo_error'),
								promoField = cartElements.promoField;
							if(promoDiv.hasClass('hidden')) {
								promoDiv.removeClass('hidden');
							} else {
								promoDiv.addClass('hidden');
								// hide error when unchecked
								if(cart.utils.promoErrorTimeout !== null) {
									clearTimeout(cart.utils.promoErrorTimeout);
								}
								promoError.addClass('hidden');
								// clear promo field when unchecked
								promoField.val('');
							}
						});
					}
					//cartElements.applyPromoBtn.on('click', cart.ajx.applyPromo);
					cartElements.promoField.on('blur', function() {
						var code = $(this).val();
						if(code !== '') {
							cart.ajx.applyPromo(code);
						}
					});
					cartElements.cartSummary.on('click', '.ajx-del-promo', cart.ajx.deletePromo);
					
					/*
						 === Gift certificates ===
					*/
					
					// apply gift certificates
					cartElements.gcField.on('blur', function() {
						var code = $(this).val();
						if(code !== '') {
							cart.ajx.applyPayment('gc', false);
						}
					});
					// validate gift certificate password
					$('#validate_gc_password').on('click', function() {
						// set flag to 'true' if password protected
						cart.ajx.applyPayment('gc', true);
					});
					
					// cartElements.applyGCButton.on('click', function () {
					//     cart.ajx.applyPayment('gc');
					// });
					
					/*
						 === Payroll Deduction ===
					*/
					cartElements.pdEligibleChk.on('change', cart.utils.confirmPayrollElibility);
					cartElements.pdApplyBtn.on('click', function() {
						cart.ajx.applyPayment('pd');
					});
					
					/*
						 === PayPal ===
					*/
					cartElements.paypalBtn.on('click', function() {
						if(shippingMethodSelected()) {
							cart.errorHandler.hideShippingError();
							if($('.rdo-ship:checked').val() === 'INSTORE') {
								cart.ajx.validatePaypalPayment({
									storePickup: true
								});
							} else {
								cart.ajx.validatePaypalPayment({
									storePickup: false
								});
							}
						} else {
							cart.errorHandler.throwShippingError();
						}
					});
					
					/*
						 === Purchase orders ===
					*/
					// apply purchase orders
					cartElements.poApplyBtn.on('click', function() {
						// set flag to 'false' if not password protected
						cart.ajx.applyPayment('po', false);
					});
					
					// validate purchase order password
					$('#validate_po_password').on('click', function() {
						// set flag to 'true' if password protected
						cart.ajx.applyPayment('po', true);
					});
					// delete payment
					cartElements.cartSummary.on('click', '.ajx-del-payment', cart.ajx.deletePayment);
					
					/*
						 === In-store pickup ===
					*/
					cart.utils.initInstorePickupOnSelected();
					
					// Check if promo code field is available
					if ( cartElements.promoCode.length > 0) {
						// Check promo value is not empty or undefined
						if(cartElements.promoCode.val() !== undefined && cartElements.promoCode.val() !== '') {
							cart.ajx.applyPromo(cartElements.promoCode.val());
						}
					}
					// After cart initial load, check all required inputs and toggle Checkout buttons accordingly
					// cart.utils.checkAllRequiredInputs();
				}
			}; // end of cart object
			
			cart.init();
			
		}; // end of initSecureCheckout
		
		// For faster page load, functions are not invoked unless needed.
		if(isShoppingCart) {
			// 1. Shopping Cart Ajax
			// Source: ../modules/ajax.js
			ajaxLib.shoppingCart.init();
		} else if(isSecureCheckout) {
			// 2. Secure Checkout Ajax
			// Source: internal **
			// ** checkout is important and complex
			// and shouldn't be moved to external file yet (not to break it)
			initSecureCheckout();
		}
		
	}());
	
});